import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

DateTitle.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
  fontSize: PropTypes.string
}


const StyleDateTitle = styled.div`
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  letter-spacing: 0.1em;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tums";
`

export default function DateTitle(props){
  const { children, color, fontWeight, fontSize } = props
  return(
    <StyleDateTitle
      color={color}
      fontWeight={fontWeight}
      fontSize={fontSize}
    >
      {children}
    </StyleDateTitle>
  )
}