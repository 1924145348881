// 河川水位の上限と下限を決めるリストを取得
// 引数
/*
  dicts : データオブジェクト
  ticklist : グラフの基準となる値のリスト
  is_minus : マイナスの可能性があるか
*/
export const getTickList = (obj, ticklist, is_minus) => {
  // undefinedを削除
  ticklist = ticklist.filter(v => v)

  const datalist = [];
  for(let i=0; i<obj.length; i++){
    datalist.push(obj[i].value)
  }

  const tick_max = ticklist.length === 0 ? 0 : Math.max(...ticklist)
  const value_max = Math.max(...datalist)
  const value_min = Math.min(...datalist)

  if(tick_max === 0 || value_max > tick_max){
    return(
      is_minus && value_min < 0 ?
        [Math.floor(value_min - 0.5), Math.ceil(value_max + 0.5)]
        : [0, Math.ceil(value_max + value_max / 10)]
    )
  }
  else{
    return(
      is_minus ?
        [Math.floor(value_min - 0.5), Math.ceil(tick_max + 0.5)]
        : [0, Math.ceil(tick_max + tick_max / 10)]
    )
  }
}
