import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  MapContainer,
  MapConsumer,
  ZoomControl,
  TileLayer,
  useMap,
  useMapEvents,
  LayersControl,
  LayerGroup,
  Marker,
  Popup
} from 'react-leaflet'
import { CRS, project, latLng } from 'leaflet'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import IconButton from '@mui/material/IconButton'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Atomic Component
import RiverTile from '../../atoms/rivertile'
import VectorTile from '../../atoms/vectortile'
import { svgCircleIcon } from '../../atoms/svgcircleicon'

// Item Component
import Sliders from '../../items/sliders'
import MapLegend from '../../items/maplegend'
import DateCard from '../../items/datecard'

// Original function
import {getMapLegendData} from '../../func/getmaplegenddata'
import {get4thMeshCode} from '../../func/get4thmeshcode'
import {dateToMMDDHHmm} from '../../func/datetoview'
import {getChrColor} from '../../func/getcolor'

const fetcher = url => axios.get(url).then(res => res.data)

// 大分県領域 funcType : prefにアクセスできるIDリスト
const ACCESSIBLE_ID = config['accessibleId']

const baseStyles = {
  zIndex : 2
};
// Mapコンポーネントのラッパー
const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 60vh;
`
// ポップアップのデザイン
const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const PopupText = styled.span`
  font-weight: bold;
  margin: 0.1rem;
`
// IconButtonのデザイン
const StyleIconBtn = styled(IconButton)`
  &&&.MuiButtonBase-root{
    position: absolute;
    z-index: 500;
    background-color: white;
    border-radius: 5px;
    top: 4.5rem;
    right: 10px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    width: 45px;
    height: 45px;
    padding: 0;
    &:hover{
      background-color: #acb4c5;
    }
  }
  &>svg{
    width: 36px;
    height: 36px;
    color: black;
  }
`

// Map Overlay Sytle
const StyleFullScreen = styled(FullScreen)`
  width: 100%;
  height: 100%;
  &.fullscreen-enabled>div{
    height: 100%;
  }
`
const StyleMapContainer = styled(MapContainer)`
  width: 100%;
  height: 100%;
  &&& .baseLayers{
    -webkit-filter: grayscale(100%);
    /* filter: invert(100%) grayscale(100%); */
    filter: grayscale(100%);
    z-index: 1 !important;
  }
  &&& .leaflet-control-layers-overlays, .leaflet-control-layers-base{
    text-align: left;
  }
  &&& .leaflet-control-zoom{
    margin-top: 85px !important;
  }
`

const baseLayerObj = {
  '1時間雨量' : 'h1_msh',
  '24時間雨量' : 'h24_msh',
  '48時間雨量' : 'h48_msh',
  '土壌雨量指数' : 'sr_msh',
  '土砂災害発生リスク' : 'rsk_msh',
  '河川洪水発生リスク' : 'rfs_msh',
  '流域平均1時間雨量' : 'h1_bsn',
  '流域平均24時間雨量' : 'h24_bsn',
  '流域平均48時間雨量' : 'h48_bsn',
  '流域平均流出量' : 'of_bsn'
}

// ベクタータイル用関数
function SetVectorTile({i, elem, chooseNum, dateArray, tileUrl, updatePopupData}){
  if(i === 0){
    return(
      <LayersControl.BaseLayer name={elem.name} checked>
        <LayerGroup>
          <VectorTile
            chooseNum={chooseNum}
            dateArray={dateArray}
            category={elem.category}
            is_river={elem.is_river}
            urlHead={`${tileUrl}/services/${elem.category}/tiles/`}
            maxZoomSize={elem.maxZoomSize}
          />
        </LayerGroup>
      </LayersControl.BaseLayer>
    )
  }
  else if(elem.is_river){
    return(
      <LayersControl.BaseLayer name={elem.name}>
        <LayerGroup>
          <RiverTile
            urlHead={`${tileUrl}/services/river_geom/tiles/`}
            updatePopupData={updatePopupData}
          />
          <VectorTile
            chooseNum={chooseNum}
            category={elem.category}
            is_river={elem.is_river}
            urlHead={`${tileUrl}/services/${elem.category}/tiles/`}
            maxZoomSize={elem.maxZoomSize}
          />
        </LayerGroup>
      </LayersControl.BaseLayer>
    )
  }
  else{
    return(
      <LayersControl.BaseLayer name={elem.name}>
        <LayerGroup>
          <VectorTile
            chooseNum={chooseNum}
            category={elem.category}
            is_river={elem.is_river}
            urlHead={`${tileUrl}/services/${elem.category}/tiles/`}
            maxZoomSize={elem.maxZoomSize}
          />
        </LayerGroup>
      </LayersControl.BaseLayer>
    )
  }
}

// マップ用のイベントハンドラ
function MyMapEventHandler({
  updateCodes,
  updateLatlng,
  updateCategories,
  updatePopupLatlng,
}){
  const map = useMapEvents({
    click: (e) => {
      updateLatlng([e.latlng.lat, e.latlng.lng])
      updateCodes(get4thMeshCode(e.latlng.lat, e.latlng.lng))
    },
    mouseover: (e) => {
      updatePopupLatlng(e.latlng)
    },
    baselayerchange: (e) => {
      let layerName = e.name
      updateCategories(baseLayerObj[layerName])
    }
  })
  return null
}

DashboardMap.propTypes = {
  // タイルのURL
  tileUrl: PropTypes.string,
  // 緯度経度
  latlng: PropTypes.array,
  // 選択されている日付
  chooseDate : PropTypes.string,
  // 選択されている日付のインデックス
  chooseNum : PropTypes.number,
  // 最新の更新時間
  newestDate: PropTypes.string,
  // 日付リスト
  dateArray : PropTypes.array,
  // ベクタータイルのリスト
  // category, name, maxZoomSize, is_river 1つめは必ず解析雨量レーダーとすること
  vectorlist : PropTypes.array,
  // 現在選択されている時刻が予測か
  isForecast : PropTypes.bool,
  // 位置選択時，メッシュコードを更新する関数
  updateCodes : PropTypes.func,
  // 位置選択時，緯度経度を更新する関数
  updateLatlng : PropTypes.func,
  // キーとなる日付の状態を更新する関数
  updateChooseDate : PropTypes.func,
  // 日付のインデックスの状態を更新する関数
  updateChooseNum : PropTypes.func,
}

export default function DashboardMap(props){
  const {
      tileUrl,
      latlng,
      chooseDate,
      chooseNum,
      newestDate,
      dateArray,
      vectorlist,
      isForecast,
      updateCodes,
      updateLatlng,
      updateChooseDate,
      updateChooseNum
    } = props

  // Mapのstateを宣言
  const [map, setMap] = useState(null)
  // ベクタータイル選択レイヤー名
  const [category, setCategory] = useState(vectorlist[0].category)
  // 河川名のポップアップのデータ
  const [popupData, setPopupData] = useState({})
  // ポップアップを表示するか否か
  const [isPopup, setIsPopup] = useState(false)
  // ポップアップを表示する緯度経度
  const [popupLatlng, setPopupLatlng] = useState([])
  // 全画面表示切り替え
  const fullscreenHandle = useFullScreenHandle()

  // クロノロジー表示のためのユーザー情報取得
  const userName = JSON.parse(localStorage.getItem('user'))
  // クロノロジーを表示するユーザーか
  const isChrUser = ACCESSIBLE_ID.includes(userName['id'])

  // クロノロジーのポイント情報を取得する
  const { data, error } = useSWR(
    () =>
      `${config.restUrl}/api/pref/chr/points/`,
    fetcher,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // 再試行は2回まで
        if(retryCount >= 2) return
        // 5秒後に再試行
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000)
      },
      refreshInterval: 300000
    }
  )

  // データ取得時のフラグ
  const isLoading = !data && !error
  const isData = !isLoading && !error
  const chrData = isData ? data.results.filter(e => e.choose_num <= chooseNum) : false

  // 子コンポーネントに引き渡す関数
  function updatePopupData(data, flag){
    setPopupData(data)
    setIsPopup(flag)
  }
  function updateCategories(text){
    setCategory(text)
  }
  function updatePopupLatlng(latlng){
    setPopupLatlng(latlng)
  }

  useEffect(() => {
    if(map !== null && latlng !== null){
      map.setView(latlng)
    }
  }, [latlng])


  // Then click for Map full screen button, switching full screen
  function switchingFullScreen(e){
    fullscreenHandle.active ?
      fullscreenHandle.exit() :
      fullscreenHandle.enter();
  }

  // ローディングと同様の扱いを行う
  if(latlng === null) return(
    <StyleFullScreen
      handle={fullscreenHandle}
      enabled={true}
    >
      <MapWrapper>
        <DateCard
          text={dateToMMDDHHmm(chooseDate)}
          is_forecast={isForecast}
        />
        <Sliders
          chooseNum={chooseNum}
          dateArray={dateArray}
          intervals={1000}
          updateChooseDate={updateChooseDate}
          updateChooseNum={updateChooseNum}
        />
        <StyleIconBtn
          className='fullscreeen-button'
          aria-label='full screen switch'
          component='span'
          onClick={switchingFullScreen}
        >
          { fullscreenHandle.active
            ? <FullscreenExitIcon />
            : <FullscreenIcon />
          }
        </StyleIconBtn>
      </MapWrapper>
    </StyleFullScreen>
  )

  return(
    <StyleFullScreen
      handle={fullscreenHandle}
      enabled={true}
    >
      <MapWrapper>
        <StyleMapContainer
          center={latlng}
          zoom={10}
          maxZoom={14}
          crs={CRS.EPSG3857}
          whenCreated={setMap}
          zoomControl={false}
          prefCanvas={true}
        >
          <ZoomControl position='topleft' />
          <TileLayer
            attribution='出典：<a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>'
            url='https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png'
            className='baseLayers'
            style={baseStyles}
          />
          <LayersControl position="topright">
            { vectorlist.map((elem,i) =>
              <SetVectorTile
                key={i.toString()}
                i={i}
                elem={elem}
                chooseNum={chooseNum}
                dateArray={dateArray}
                tileUrl={tileUrl}
                updatePopupData={updatePopupData}
              />
            )}
            <LayersControl.Overlay name="土砂災害警戒区域等">
              <LayerGroup>
                <TileLayer
                  url="https://disaportaldata.gsi.go.jp/raster/05_dosekiryukeikaikuiki/{z}/{x}/{y}.png"
                  className='addLayers'
                />
                <TileLayer
                  url="https://disaportaldata.gsi.go.jp/raster/05_kyukeishakeikaikuiki/{z}/{x}/{y}.png"
                  className='addLayers'
                />
                <TileLayer
                  url="	https://disaportaldata.gsi.go.jp/raster/05_jisuberikeikaikuiki/{z}/{x}/{y}.png"
                  className='addLayers'
                />
                <TileLayer
                  url="https://disaportaldata.gsi.go.jp/raster/05_dosekiryukikenkeiryu/{z}/{x}/{y}.png"
                  className='addLayers'
                />
                <TileLayer
                  url="https://disaportaldata.gsi.go.jp/raster/05_kyukeisyachihoukai/{z}/{x}/{y}.png"
                  className='addLayers'
                />
                <TileLayer
                  url="https://disaportaldata.gsi.go.jp/raster/05_jisuberikikenkasyo/{z}/{x}/{y}.png"
                  className='addLayers'
                />
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="洪水浸水想定区域(想定最大規模)">
              <TileLayer
                url="https://disaportaldata.gsi.go.jp/raster/01_flood_l2_shinsuishin_data/{z}/{x}/{y}.png"
                className='addLayers'
              />
            </LayersControl.Overlay>
            <LayersControl.Overlay name="津波浸水想定">
              <TileLayer
                url="https://disaportaldata.gsi.go.jp/raster/04_tsunami_newlegend_data/{z}/{x}/{y}.png"
                className='addLayers'
              />
            </LayersControl.Overlay>
          </LayersControl>

          <MyMapEventHandler
            updateCodes={updateCodes}
            updateLatlng={updateLatlng}
            updateCategories={updateCategories}
            updatePopupLatlng={updatePopupLatlng}
          />

          {
            isData && isChrUser && chrData.map((e, i) => (
              <Marker
                key={`marker-chr-${i}`}
                position={[e.latitude, e.longitude]}
                icon={svgCircleIcon('#ff2600')}
              />
            ))
          }

          <Marker position={latlng} />
          {
            isPopup &&
              <Popup position={popupLatlng}>
                <PopupWrapper>
                  <PopupText>{popupData.name}</PopupText>
                </PopupWrapper>
              </Popup>
          }
          <MapLegend {...getMapLegendData(category)} isChrUser={isChrUser} />
        </StyleMapContainer>
        <DateCard
          text={dateToMMDDHHmm(chooseDate)}
          is_forecast={isForecast}
        />
        <Sliders
          chooseNum={chooseNum}
          dateArray={dateArray}
          intervals={1000}
          updateChooseDate={updateChooseDate}
          updateChooseNum={updateChooseNum}
        />
        <StyleIconBtn
          className='fullscreeen-button'
          aria-label='full screen switch'
          component='span'
          onClick={switchingFullScreen}
        >
          { fullscreenHandle.active
            ? <FullscreenExitIcon />
            : <FullscreenIcon />
          }
        </StyleIconBtn>
      </MapWrapper>
    </StyleFullScreen>
  )
}
