import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyleHandle = styled.div`
  left: ${(props) => props.percent}%;
  position: absolute;
  margin-top: 0.5rem;
  margin-left: -10px;
  z-index: 1000;
  width: 20px;
  height: 20px;
  border: 0;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  color: #233C6D;
`

export default function Handle({
  handle: {id, value, percent},
  getHandleProps
}){

  return (
    <StyleHandle
      percent={percent}
      {...getHandleProps(id)}
    />
  )
}