import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip } from 'react-leaflet'

// Atomic Component
import Card from '../card'

// Original function
import { getCardColor } from '../../func/getcolor'
import { get_colorclass_from_class } from '../../func/getcolor'


MapToolTip.propTypes = {
  // ツールチップのタイトル
  label : PropTypes.string,
  // ツールチップの値
  value : PropTypes.string,
  // ツールチップの単位
  unit : PropTypes.string,
  // 色番号
  colorclass : PropTypes.number,
}

export default function MapToolTip(props){
  const {label, value, unit, colorclass} = props
  return(
    <OverLayToolTip colorclass={colorclass}>
      <StyleHead>{label}</StyleHead>
      <StyleValue>{value} {unit}</StyleValue>
    </OverLayToolTip>
  )
}


const StyleHead = styled.div`
  font-weight: bold;
  border-bottom: thin solid;
  border-color: rgba(255, 255, 255, 0.75);
`
const StyleValue = styled.div`
  margin-top: 0.5rem;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`

const OverLayToolTip = styled(Tooltip)`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  background-color: ${(props) => getCardColor(get_colorclass_from_class(props.colorclass))};
  margin: 0.5rem 0;
  filter: brightness(1.25);
`