import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Item component
import BarGraph from '../../items/bargraph'
import LineGraph from '../../items/linegraph'

// Atom component
import ErrorMsg from '../../atoms/errormsg'

// original function
import { getTickList } from '../../func/getticklist'
import { getGraphLine } from '../../func/getgraphline'

const fetcher = url => axios.get(url).then(res => res.data)

GraphControl.propTypes = {
  // タブで選択している種類
  chooseCategory: PropTypes.string,
  // 自分の種類，一致すればグラフを表示
  category: PropTypes.string,
  // 選択している場所のメッシュコード
  code: PropTypes.number,
  // グラフ情報
  info: PropTypes.object,
}

function GraphPlot(props){
  const { code, info } = props

  // データ取得
  const { data, error } = useSWR(
    () => (code && info) ?
      `${config.restUrl}/api/rain/graph/${info.category}/${code}/`
      : null,
    fetcher,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // 再試行は5回まで
        if(retryCount >= 2) return
        // 5秒後に再試行
        setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
      },
      refreshInterval: 300000
    }
  )
  const isLoading = !data && !error

  if(isLoading){
    return(<React.Fragment />)
  }
  if(error){
    return(<ErrorMsg msg={'データが見つかりませんでした'} />)
  }

  // グラフの設定
  const domains = info.category === 'sr_msh' ?
            getTickList(data.results, data.RainStd, false)
            : getTickList(data.results, info.ticks, false)

  const graphLines = info.category === 'sr_msh' && data.RainStd.length !== 0 ?
            getGraphLine(data.RainStd, ['注意報', '警報', '特別警報'])
            : getGraphLine(info.ticks, info.tickLabel)

  if(info.isUnit){
    return(
      <BarGraph
        category={info.category.split('_')[0]}
        data={data.results}
        domains={domains}
        unit={info.unit}
        xaxisKeyList={info.xaxisKeyList}
        dataKey={info.dataKey}
        lines={graphLines}
        lineWidth={3}
      />
    )
  }
  else{
    return(
      <LineGraph
        category={info.category.split('_')[0]}
        data={data.results}
        domains={domains}
        unit={info.unit}
        xaxisKeyList={info.xaxisKeyList}
        dataKey={info.dataKey}
        lines={graphLines}
        lineWidth={3}
      />
    )
  }

  return (
    <React.Fragment />
  )
}


export default function GraphControl(props){
  const { chooseCategory, category, code, info} = props

  return (
    <div
      role="tabpanel"
      hidden={chooseCategory !== category}
      style={{height: 'calc(100% - 32px)'}}
      id={`wrapped-tabpanel-${category}`}
      aria-labelledby={`wrapped-tab-${category}`}
    >
      {chooseCategory === category && (
        <GraphPlot
          code={code}
          info={info}
        />
      )}
    </div>
  );
}