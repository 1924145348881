import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  MapContainer,
  MapConsumer,
  ZoomControl,
  TileLayer,
  useMap,
  useMapEvents,
  LayersControl,
  LayerGroup,
  Marker,
  Popup,
  Tooltip
} from 'react-leaflet'
import { CRS, project, latLng } from 'leaflet'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import IconButton from '@mui/material/IconButton'

// Item Component
import MapLegend from '../../items/maplegend'

// Atom Component
import { svgCircleIcon } from '../../atoms/svgcircleicon'
import MapToolTip from '../../atoms/maptooltip'
import { svgSquareIcon } from '../../atoms/svgsquareicon'

// Original function
import {getMapLegendData} from '../../func/getmaplegenddata'
import { getTelVectorStyle } from '../../func/getvectortilestyles'
import { config } from '../../config.jsx'
import { getCardColor } from '../../func/getcolor'
import { get_colorclass_from_class } from '../../func/getcolor'

ShelterMap.propTypes = {
  // 避難所の位置情報が入る配列
  latlngData : PropTypes.array,
  // 最初のズームレベル
  firstZoomLevel : PropTypes.number,
  // 選択中の避難所を変更する関数
  updateChooseShelter : PropTypes.func,
}

const baseStyles = {
  zIndex : 2
}

/// Map Events Handler
function MyMapEventHandler({updateCurrentZoomLevel}){
  const map = useMapEvents({
    zoomend: (e) => {
      updateCurrentZoomLevel(map.getZoom())
    }
  })
  return null
}

export default function ShelterMap(props){
  const { latlngData, firstZoomLevel, updateChooseShelter } = props
  const lat_center = 33.2305526
  const lng_center = 131.6068285
  const latlng = [lat_center, lng_center]

  // Mapのstateを宣言
  const [map, setMap] = useState(null)
  // Mapのズームレベルを状態管理
  const [currentZoomLevel, setCurrentZoomLevel] = useState(firstZoomLevel)
  // 全画面表示切り替え
  const fullscreenHandle = useFullScreenHandle()

  // Then click for Map full screen button, switching full screen
  function switchingFullScreen(e){
    fullscreenHandle.active ?
      fullscreenHandle.exit() :
      fullscreenHandle.enter()
  }

  // ズームレベルが変化したときにアップデートする関数
  function updateCurrentZoomLevel(e){
    setCurrentZoomLevel(e)
  }

  return(
    <StyleFullScreen
      handle={fullscreenHandle}
      enabled={true}
    >
      <MapWrapper>
        <StyleMapContainer
          center={latlng}
          zoom={firstZoomLevel}
          maxZoom={15}
          crs={CRS.EPSG3857}
          whenCreated={setMap}
          zoomControl={false}
          prefCanvas={true}
        >
          <ZoomControl position='topleft' />
          <TileLayer
            attribution='出典：<a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>'
            url='https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png'
            className='baseLayers'
            style={baseStyles}
          />
          <MyMapEventHandler
            updateCurrentZoomLevel={updateCurrentZoomLevel}
          />
          {
            latlngData && latlngData.map((elem, i) => (
              <Marker
                key={`marker-${i}`}
                position={[elem.latitude, elem.longitude]}
                icon={
                  elem.sort_name === '閉鎖' ?  svgSquareIcon(getTelVectorStyle(elem.colorclass))
                  : svgCircleIcon(getTelVectorStyle(elem.colorclass))
                }
                eventHandlers={{
                  click: () => {
                    updateChooseShelter(elem.shelter_name, elem.suitable)
                  }
                }}
              >
                <MapToolTip
                  label={elem.shelter_name}
                  value={elem.sort_name === '閉鎖' ? elem.sort_name : elem.capacity === 0 ? '避難者なし' : elem.capacity}
                  unit={elem.sort_name === '閉鎖' || elem.colorclass === 0 ? '' : '%'}
                  colorclass={elem.colorclass}
                />
              </Marker>
            ))
          }
          <MapLegend {...getMapLegendData('shelter')} />
        </StyleMapContainer>
        <StyleIconBtn
          className='fullscreen-button'
          aria-label='full screen switch'
          component='span'
          onClick={switchingFullScreen}
        >
          {
            fullscreenHandle.active
              ? <FullscreenExitIcon />
              : <FullscreenIcon />
          }
        </StyleIconBtn>
      </MapWrapper>
    </StyleFullScreen>
  )

}

// Mapコンポーネントのラッパー
const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
`

// Map Overlay Sytle
const StyleFullScreen = styled(FullScreen)`
  width: 100%;
  height: 100%;
  &.fullscreen-enabled>div{
    height: 100%;
  }
`
const StyleMapContainer = styled(MapContainer)`
  width: 100%;
  height: 100%;
  &&& .baseLayers{
    -webkit-filter: grayscale(100%);
    /* filter: invert(100%) grayscale(100%); */
    filter: grayscale(100%);
    z-index: 1 !important;
  }
  &&& .leaflet-control-layers-overlays, .leaflet-control-layers-base{
    text-align: left;
  }
  &&& .leaflet-control-zoom{
    margin-top: 85px !important;
  }
`

// IconButtonのデザイン
const StyleIconBtn = styled(IconButton)`
  &&&.MuiButtonBase-root{
    position: absolute;
    z-index: 500;
    background-color: white;
    border-radius: 5px;
    top: 4.5rem;
    right: 10px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    width: 45px;
    height: 45px;
    padding: 0;
    &:hover{
      background-color: #acb4c5;
    }
  }
  &>svg{
    width: 36px;
    height: 36px;
    color: black;
  }
`

const OverLayToolTip = styled(Tooltip)`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  background-color: ${(props) => getCardColor(get_colorclass_from_class(props.colorclass))};
  margin: 0.5rem 0;
  filter: brightness(1.25);
`
