import React, { useState } from 'react'
import styped from 'styled-components'

// Template components
import ShelMapDashboard from '../../templates/shelmapdashboard'

export default function ShelterMap(){

  return(
    <ShelMapDashboard/>
  )

}