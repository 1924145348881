import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'


// Contents Components
import ShelterGraph from '../sheltergraph'
import ShelterMap from '../sheltermap'

// Atom Components
import ErrorMsg from '../../atoms/errormsg'

const fetcher = url => axios.get(url).then(res => res.data)

export default function ShelterMapView(){
  // 選択中の避難所名
  const [shelterName, setShelterName] = useState('')
  // 選択中の避難所タイプ
  const [shelterType, setShelterType] = useState('')

  const { data, error } = useSWR(
    () =>
      `${config.restUrl}/api/pref/shelter/points/`,
    fetcher,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // 再試行は5回まで
        if(retryCount >= 2) return
        // 5病後に再試行
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000)
      },
      refreshInterval: 300000
    }
  )

  const isLoading = !data && !error
  const isData = !isLoading && !error
  const latlngData = isData && data.results

  function updateChooseShelter(name, type){
    setShelterName(name)
    setShelterType(type)
  }

  if(isData && !shelterName){
    setShelterName(latlngData[0].shelter_name)
    setShelterType(latlngData[0].suitable)
  }

  if(error){
    return(
      <React.Fragment>
        <ListWrapper>
          <ShelterMap
            latlngData={latlngData}
            firstZoomLevel={10}
            updateChooseShelter={updateChooseShelter}
          />
        </ListWrapper>
        <GraphWrapper>
          <ErrorWrapper>
            <ErrorMsg msg={'現在開設されている避難所はありません'} />
          </ErrorWrapper>
        </GraphWrapper>
      </React.Fragment>
    )
  }

  return(
    <React.Fragment>
      <ListWrapper>
        <ShelterMap
          latlngData={latlngData}
          firstZoomLevel={10}
          updateChooseShelter={updateChooseShelter}
        />
      </ListWrapper>
      <GraphWrapper>
        <ShelterGraph
          shelterName={shelterName}
          shelterType={shelterType}
        />
      </GraphWrapper>
    </React.Fragment>
  )

}

// マップラッパー
const ListWrapper = styled.div`
  display: flex;
  width: 50vw;
  height: 100%;
  padding-left: 5vw;
  margin: 0.5rem 0;
`

// グラフラッパー
const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50vw;
  padding-right: 5vw;
  padding-left: 1rem;
  margin: 0.5rem 0;
  height: auto;
  overflow-y: scroll;
`

// エラーラッパー
const ErrorWrapper = styled.div`
  display: flex;
  height: 100%;
`