import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Contents Component
import RiverGraph from '../rivergraph'
import RiverMap from '../rivermap'

TelMapView.propTypes = {
  // 選択している日付
  chooseDate: PropTypes.number,
}

const fetcher = url => axios.get(url).then(res => res.data)

export default function TelMapView(props){
  const { chooseDate } = props
  // 選択中の水位計のID
  const [ mtrCode, setMtrCode ] = useState(1126500016)
  // 選択中の水位計の種類
  const [ mtrCategory, setMtrCategory ] = useState('tel')

  const { data, error } = useSWR(
    () =>
      `${config.restUrl}/api/mtr/tel/points/`,
    fetcher,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // 再試行は2回まで
        if(retryCount >= 2) return
        // 5秒後に再試行
        setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
      },
      refreshInterval: 300000
    }
  )

  const isLoading = !data && !error
  const isData = !isLoading && !error
  const pointsData = isData && data.results.filter(elem => elem.bints === chooseDate)

  function updateChooseMtr(code, category){
    setMtrCode(code)
    setMtrCategory(category)
  }

  return(
    <React.Fragment>
      <ListWrapper>
        <RiverMap
          chooseDate={chooseDate}
          pointsData={pointsData}
          firstZoomLebel={10}
          vectorlist={vectorlist}
          updateChooseMtr={updateChooseMtr}
        />
      </ListWrapper>
      <GraphWrapper>
        <RiverGraph
          mtrCode={mtrCode}
          mtrCategory={mtrCategory}
        />
      </GraphWrapper>
    </React.Fragment>
  )

}

const vectorlist = [
  {
    category: 'h1_msh',
    name: '1時間雨量',
    maxZoomSize: 12,
    is_river: false,
  },
  {
    category: 'h24_msh',
    name: '24時間雨量',
    maxZoomSize: 12,
    is_river: false,
  },
  {
    category: 'h48_msh',
    name: '48時間雨量',
    maxZoomSize: 12,
    is_river: false,
  },
  {
    category: 'sr_msh',
    name: '土壌雨量指数',
    maxZoomSize: 12,
    is_river: false,
  },
  {
    category: 'rsk_msh',
    name: '土砂災害発生リスク',
    maxZoomSize: 12,
    is_river: false,
  }
]

// マップラッパー
const ListWrapper = styled.div`
  display: flex;
  width: 50vw;
  height: 100%;
  padding-left: 5vw;
  margin: 0.5rem 0;
`

// グラフラッパー
const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50vw;
  padding-right: 5vw;
  padding-left: 1rem;
  margin: 0.5rem 0;
  height: auto;
  overflow-y: scroll;
`