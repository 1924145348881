import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// atom components
import LinkText from '../../atoms/linktext'
import ChangeIcon from '../../atoms/changeicon'
import BorderBand from '../../atoms/borderband'

// user defined function
import { get_colorclass_from_class, getCardColor } from '../../func/getcolor'

MtrInfo.propTypes = {
  // メーター名
  mtrName: PropTypes.string,
  // メーターのカテゴリ名
  basName: PropTypes.string,
  // メーターの詳細カテゴリ名
  riverName: PropTypes.string,
  // メーター情報
  mtrDetail : PropTypes.string,
  // メーター設置緯度
  mtrLat : PropTypes.number,
  // メーター設置経度
  mtrLng : PropTypes.number,
  // メーター設置住所
  mtrAddress : PropTypes.string,
  // メーター変化フラグ
  mtrDiffFlag : PropTypes.number,
  // メーター値
  mtrValue : PropTypes.number,
  // メーターに応じた色クラス番号
  colorclass : PropTypes.number,
  // メーターの基準線
  mtrBorder : PropTypes.array,
  // メーターの単位
  mtrUnit : PropTypes.string,
  // メーターの表示文言
  mtrLabel : PropTypes.array,
  // メーターの表示機能(特別ケース時)
  mtrViewer : PropTypes.string,
  // 値が無いときにボードに表示する文言
  isEmptyMsg : PropTypes.string,
}

export default function MtrInfo(props){
  const {
    mtrName,
    basName,
    riverName,
    mtrDetail,
    mtrLat,
    mtrLng,
    mtrAddress,
    mtrDiffFlag,
    mtrValue,
    colorclass,
    mtrBorder,
    mtrUnit,
    mtrLabel,
    mtrViewer,
    isEmptyMsg
  } = props

  return(
    <Wrapper colors={get_colorclass_from_class(colorclass)}>
      <Head>
        <HeadName>
          <Title>{mtrName}</Title>
          <RiverName>{basName} - {riverName}</RiverName>
          <RiverName>{mtrDetail}</RiverName>
          <LinkText
            url={`/risk/${mtrLat}/${mtrLng}`}
            target={'_blank'}
            text={'雨量を確認'}
          />
        </HeadName>
      </Head>
      <DetailWrapper>{mtrAddress}</DetailWrapper>
      <MtrWrapper>
        <ChangeIcon flag={mtrDiffFlag} />
        {
          mtrDiffFlag === 3 || !mtrValue ? <ValueWrapper>{isEmptyMsg}</ValueWrapper>
          : mtrViewer ? <ValueWrapper>{mtrViewer}</ValueWrapper>
          : <ValueWrapper>{mtrValue} {mtrUnit}</ValueWrapper>
        }
      </MtrWrapper>
      <BorderBand
        borderArray={mtrBorder}　
        borderUnit={mtrUnit}
        borderLabel={mtrLabel}
      />
    </Wrapper>
  )
}


const Wrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  border-radius: 5px;
  background-color: ${(props) => getCardColor(props.colors)};
`
const Head = styled.div`
  display: flex;
  margin: 0.5rem 1rem 0 1rem;
  justify-content: space-between;
}
`
const HeadName = styled.div`
  display: flex;
`
const Title= styled.div`
  font-size: 24px;
  color: white;
  font-weight: bold;
`
const RiverName = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 18px;
  color: white;
  margin-left: 1.5rem;
`
const DetailWrapper = styled.div`
  font-size: 18px;
  color: white;
  text-align: left;
  margin: 0.5rem 1rem;
`
const MtrWrapper = styled.div`
  display: flex;
  justify-content: center;
  color: white;
  font-size: 20px;
  align-items: center;
  margin: 1rem 0;
`
const ValueWrapper = styled.div`
  font-size: 28px;
  font-weight: bold;
  margin: 0 1.5rem;
`
