import React from 'react'
import styled, { keyframes, css } from 'styled-components'

export default function LoadingMeter(){
  return(
    <StyleCard className={'meter'} >
      <HeadTitle/>
      <Values />
      <SubTitle />
    </StyleCard>
  )
}

const KeyframesFullView = keyframes`
  10% {
    width: 100%;
  }
`
const KeyframesShimmer = keyframes`
  0% {
    background-position: -80vw 0;
  }
  100% {
    background-position: 80vw 0;
  }
`
const shimmerAnimation = css`
  background: linear-gradient(to right, #5e5e5e 8%, #646464 18%, #5e5e5e 28%);
  background-size: 80vw 100%;
  animation: ${KeyframesShimmer} 1.5s infinite linear;
`
const HeadTitle = styled.div`
  height: 16px;
  text-align: left;
  width: 75%;
  border-radius: 5px;
  ${shimmerAnimation}
`
const Values = styled.div`
  height: 28px;
  justify-contents: center;
  width: 70%;
  margin: 0.75rem 15%;
  border-radius: 5px;
  ${shimmerAnimation}
`
const SubTitle = styled.div`
  height: 16px;
  text-align: right;
  width: 75%;
  margin-left: 25%;
  border-radius: 5px;
  ${shimmerAnimation}
`
const StyleCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  background-color: rgb(77, 77, 78);
  max-width: 20vw;
`
