import moment from 'moment'

// 見せられる形に日付のstringを変更
export const dateToMMDDHHmm = (props) => {
  if(!props) return ''
  return moment(props).add(9, 'hours').format('M月D日 HH:mm')
}

export const JSTToMMDDHHmm = (props) => {
  if(!props) return ''
  return moment(props).format('M月D日 HH:mm')
}
export const dateToMMDDHHmmGraph = (props) => {
  if(!props) return ''
  return moment(props).add(9, 'hours').format('MM/DD HH:mm')
}