import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Label
} from 'recharts'

// Atomic Component
import GraphToolTip from '../../atoms/graphtooltip'
// original function
import { dateToMMDDHHmmGraph } from '../../func/datetoview'

LineGraph.propTypes = {
  // 表示するデータの種類を示す
  category: PropTypes.string,
  // グラフで表示するデータ，REST APIのresultsの中身を持ってくれば良い
  data: PropTypes.array,
  // グラフの上限と下限
  domains: PropTypes.array,
  // グラフ上の単位 空の場合あり
  unit: PropTypes.string,
  // グラフのx軸のキーとなるリスト
  xaxisKeyList: PropTypes.array,
  // データのキー，ここではvalue
  dataKey: PropTypes.string,
  // グラフに引く線の指定，値と線の色が入っている
  lines: PropTypes.array,
  // グラフに引く線の太さ，デフォルトは3
  lineWidth: PropTypes.number,
}

const axisStyles = {
  fontSize: '16px',
  fill: 'white',
  position: 'right'
}

const GraphWrapper = styled.div`
  display: frex;
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  padding: 0.5rem;
  background-color: #17295a;
  border-radius: 0 0 5px 5px;
`

export default function LineGraph(props){
  const {
      category,
      data,
      domains,
      unit,
      xaxisKeyList,
      dataKey,
      lines,
      lineWidth
    } = props

  return(
    <GraphWrapper>
      <ResponsiveContainer width='95%'>
        <LineChart
          data={data}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='4 4' />
          {
            xaxisKeyList.map((elem, i) =>
              <XAxis
                key={elem}
                xAxisId={i}
                dataKey={xaxisKeyList[i]}
                allowDuplicatedCategory={i===0}
                tick={axisStyles}
                tickFormatter={(dates) => dateToMMDDHHmmGraph(dates)}
              />
          )}
          <YAxis
            dataKey={dataKey}
            unit={unit}
            domain={domains}
            interval={0}
            axisLine={true}
            tick={axisStyles}
            tick={false}
          />
          <Tooltip
            content={
              <GraphToolTip unit={unit} category={category} />
            }
            cursor={{ fill: '#223C6D', opacity: 0.7}}
          />
          <Line
            dataKey={dataKey}
            stroke="#318AB7"
            strokeWidth={4}
            unit={unit}
            opacity={0.9}
          />
          {
            lines.map((elem, i) =>
              <ReferenceLine
                key={elem.label}
                y={elem.target}
                stroke={elem.color}
                strokeWidth={lineWidth ? lineWidth : 4}
              >
                <Label
                  value={`${elem.label}${unit}`}
                  position={'left'}
                  style={axisStyles}
                />
              </ReferenceLine>
          )}
        </LineChart>
      </ResponsiveContainer>
    </GraphWrapper>
  )
}


