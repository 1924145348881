import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import DashboardMap from '../../contents/dashboardmap'
import InfiniteRank from '../../contents/infiniterank'
import MeshMeter from '../../contents/meshmeter'
import RainGraph from '../../contents/raingraph'

RiskDashboard.propTypes = {
  // タイルのURL
  tileUrl: PropTypes.string,
  // 選択場所のメッシュコード
  codes: PropTypes.number,
  // 緯度経度
  latlng: PropTypes.array,
  // 選択されている日付
  chooseDate : PropTypes.string,
  // 選択されている日付のインデックス
  chooseNum : PropTypes.number,
  // 最新の解析雨量取得時間
  newestDate: PropTypes.string,
  // 日付リスト
  dateArray : PropTypes.array,
  // ベクタータイルのリスト
  // category, name, maxZoomSize, is_river 1つめは必ず解析雨量レーダーとすること
  vectorlist : PropTypes.array,
  // 現在選択されている時刻が予測か
  isForecast : PropTypes.bool,
  // 位置選択時，メッシュコードを更新する関数
  updateCodes : PropTypes.func,
  // 位置選択時，緯度経度を更新する関数
  updateLatlng : PropTypes.func,
  // キーとなる日付の状態を更新する関数
  updateChooseDate : PropTypes.func,
  // 日付のインデックスの状態を更新する関数
  updateChooseNum : PropTypes.func,
}

const AppWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`
const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 20vw;
  margin: 0.5rem;
`
const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60vw;
  height: 100%;
  margin: 0.5rem;
`
const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 20vw;
  height: calc(100vh - 1rem - 48px);
  margin: 0.5rem;
`


export default function RiskDashboard(props){
  return(
    <React.Fragment>
      <RiskHeader />
      <AppWrapper>
        <LeftWrapper>
          <MeshMeter
            codes={props.codes}
            chooseDate={props.chooseDate}
          />
        </LeftWrapper>
        <CenterWrapper>
          <DashboardMap
            tileUrl={props.tileUrl}
            latlng={props.latlng}
            chooseDate={props.chooseDate}
            chooseNum={props.chooseNum}
            newestDate={props.newestDate}
            dateArray={props.dateArray}
            vectorlist={props.vectorlist}
            isForecast={props.isForecast}
            updateCodes={props.updateCodes}
            updateLatlng={props.updateLatlng}
            updateChooseDate={props.updateChooseDate}
            updateChooseNum={props.updateChooseNum}
          />
          <RainGraph codes={props.codes} />
        </CenterWrapper>
        <RightWrapper>
          <InfiniteRank
            updateCode={props.updateCodes}
            updateLatlng={props.updateLatlng}
            updateChooseDate={props.updateChooseDate}
          />
        </RightWrapper>
      </AppWrapper>
    </React.Fragment>
  )
}