import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Item component
import LongSliders from '../../items/longsliders'

// Atom Component
import ErrorMsg from '../../atoms/errormsg'

const fetcher = url => axios.get(url).then(res => res.data)
export default function MeterSlider(props){
  const { bsnCode, updateChooseDate } = props

  const { data, error } = useSWR(
    () => bsnCode &&
      `${config.restUrl}/api/mtr/slider/date/${bsnCode}/`,
    fetcher,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // 再試行は5回まで
        if(retryCount >= 2) return
        // 5秒後に再試行
        setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
      },
      refreshInterval: 300000
    }
  )

  // データが取得できたかを確認する
  // ローディングフラグ
  const isLoading = !data && !error
  // データ取得フラグ
  const isLoadingComplete = data && !error
  // データリスト
  const dataArray = isLoadingComplete ? data.results : []
  // 最新の更新時間
  const newestDate = isLoadingComplete ? data.newest_date : ''
  // フィルターするキーリスト
  const keyArray = isLoadingComplete ? dataArray.map((elem) => elem.dbdate) : []
  // 日付リスト
  const dateArray = isLoadingComplete ? dataArray.map((elem) => elem.date) : []
  // 時間リスト
  const timeArray = isLoadingComplete ? dataArray.map((elem) => elem.time) : []

  if(isLoading){
    return(<LoadingWrapper>読み込み中</LoadingWrapper>)
  }
  if(error){
    return(
      <React.Fragment/>
    )
  }

  return(
    <React.Fragment>
        <LongSliders
          chooseFirstNum={dataArray.findIndex((elem) => elem.dbdate === newestDate)}
          chooseDate={newestDate}
          intervals={500}
          dateArray={dateArray}
          timeArray={timeArray}
          keyArray={keyArray}
          updateChooseDate={updateChooseDate}
        />
    </React.Fragment>
  )
}

const LoadingWrapper = styled.div`
  width: 100vw;
  height: 174px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: white;
`
