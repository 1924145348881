import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Atom Commponent
import SwitchBtn from '../../atoms/switchbtn'

CtvBtn.propTypes = {
  // 市町村コード
  ctvCode : PropTypes.number,
  // 市町村情報を更新する
  updateCtvStatus : PropTypes.func
}

const fetcher = url => axios.get(url).then(res => res.data)

export default function CtvBtn(props){
  const { ctvCode, updateCtvStatus } = props

  // get API
  const { data, error } = useSWR(
    () =>
      `${config.restUrl}/api/pref/city/list/44/`,
    fetcher,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // 再試行は5回まで
        if(retryCount >= 2) return
        // 5秒後に再試行
        setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
      },
    }
  )

  const isLoading = !data && !error

  // Loading
  if(isLoading){
    return(<React.Fragment />)
  }

  if(error){
    // 開設中の避難所がない場合や，通信できない場合は
    // 市町村コードを0に，市町村名をNotFoundとする
    updateCtvStatus(0, 'NotFound')
    return(<React.Fragment />)
  }

  const onClickBtn = (props) => {
    updateCtvStatus(props[0], props[1])
  }

  const firstPickData = data.result[0]
  if(ctvCode === null || ctvCode === 0 && firstPickData){
    updateCtvStatus(firstPickData.ctv_code, firstPickData.ctv_name)
  }

  return(
    <Wrapper>
      {
        data.result.map((elem, i) => (
          <SwitchBtn
            key={elem.ctv_code}
            text={elem.ctv_name}
            isChoosed={elem.ctv_code === ctvCode}
            updateChooseData={onClickBtn}
            propArray={[elem.ctv_code, elem.ctv_name]}
          />
        ))
      }
    </Wrapper>
  )

}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem 5vw;
`
