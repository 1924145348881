import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// user defined function
import { getCardColor } from '../../func/getcolor'

JMAAlert.propTypes = {
  // 気象警報名
  alertname: PropTypes.string,
  // 日付
  subtitle: PropTypes.string,
  // カラークラス名
  colorclass: PropTypes.string,
}

export default function JMAAlert(props){
  const { alertname, subtitle, colorclass } = props
  return(
    <Wrapper
      colorclass={colorclass}
    >
      <ValueWrapper>{alertname}</ValueWrapper>
      <SubtitleWrapper>{subtitle}</SubtitleWrapper>
    </Wrapper>

  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => getCardColor(props.colorclass)};
  border-radius: 2.5px;
  min-width: 120px;
  padding: 0.5rem 0.25rem 0.25rem 0.25rem;
  margin-right: 10px;
  margin-top: 10px;
`
const ValueWrapper = styled.div`
  color: white;
  font-weight: bold;
  text-align: center;
  margin: 0.25rem 0;
`
const SubtitleWrapper = styled.div`
  color: white;
  font-weight: normal;
  font-size: 12px;
  text-align: right;
`