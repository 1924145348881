import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { makeStyles } from '@mui/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography'

import GraphControl from '../graphcontrol'

RainGraph.propTypes = {
  // 選択箇所のメシュコード
  codes : PropTypes.number,
}

const GraphWrapper = styled.div`
  height: calc(40vh - 30px - 2.5rem);
  margin: 0.5rem 0;
`

const useStyles = makeStyles(theme => ({
  tabsRoot:{
    minHeight: '32px',
    height: '32px',
    backgroundColor: '#152451',
    color: '#FFFFFF',
    arignItems: 'center',
  },
  tabRoot: {
    minHeight: '32px',
    height: '32px',
    backgroundColor: '#152451',
    color: '#FFFFFF',
    arignItems: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    "&:hover": {
      backgroundColor: '#223C6D',
    },
  }
}))


function allyProps(index){
  return {
    id: `graph-tap-${index}`,
    'aria-controls' : `graph-tap-${index}`,
  };
}

export default function RainGraph(props){
  const { codes } = props
  const [chooseCategory, setChooseCategory] = useState(GraphSettings[0].category)
  const classes = useStyles()
  const handleChange = (event, newValue) => {
    setChooseCategory(newValue)
  }


  return(
    <GraphWrapper>
      <AppBar position='static'>
        <Tabs
          value={chooseCategory}
          onChange={handleChange}
          classes={{
            root: classes.tabsRoot
          }}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable force tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#318AB7"
            }
          }}
        >
          {
            GraphSettings.map((elem, i) =>
              <Tab
                key={i.toString()}
                value={elem.category}
                label={elem.text}
                classes={{root: classes.tabRoot}}
                {...allyProps(elem.category)}
              />
            )
          }
        </Tabs>
      </AppBar>
      {
        GraphSettings.map((elem, i) =>
          <GraphControl
            key={elem.category}
            chooseCategory={chooseCategory}
            category={elem.category}
            code={codes}
            info={elem}
          />
        )
      }
    </GraphWrapper>
  )

}

const GraphSettings = [
  {
    category: 'h1_msh',
    text: '1時間雨量',
    unit: 'mm/h',
    ticks: [20, 30, 50, 80],
    domains: [0, 80],
    xaxisKeyList: ['date'],
    dataKey: 'value',
    isUnit: true
  },
  {
    category: 'h24_msh',
    text: '24時間雨量',
    unit: 'mm',
    ticks: [150, 200, 250, 300],
    domains: [0, 300],
    xaxisKeyList: ['date'],
    dataKey: 'value',
    isUnit: true
  },
  {
    category: 'h48_msh',
    text: '48時間雨量',
    unit: 'mm',
    ticks: [200, 250, 300, 400],
    domains: [0, 400],
    xaxisKeyList: ['date'],
    dataKey: 'value',
    isUnit: true
  },
  {
    category: 'sr_msh',
    text: '土壌雨量指数',
    unit: '',
    ticks: [80, 120, 150, 200],
    domains: [0, 200],
    xaxisKeyList: ['date'],
    dataKey: 'value',
    isUnit: false
  },
  {
    category: 'rsk_msh',
    text: '土砂災害発生リスク',
    unit: '',
    ticks: [300, 600, 1000, 2000],
    tickLabel: ['レベル1', 'レベル2', 'レベル3', 'レベル4'],
    domains: [0, 200],
    xaxisKeyList: ['date'],
    dataKey: 'value',
    isUnit: false
  },
  // {
  //   category: 'rfs_msh',
  //   text: '河川洪水発生リスク',
  //   unit: '',
  //   ticks: [50, 75, 100, 150],
  //   tickLabel: ['レベル1', 'レベル2', 'レベル3', 'レベル4'],
  //   domains: [0, 200],
  //   xaxisKeyList: ['date'],
  //   dataKey: 'value',
  //   isUnit: false
  // },
  {
    category: 'h1_bsn',
    text: '流域1時間雨量',
    unit: 'mm/h',
    ticks: [20, 30, 50, 80],
    domains: [0, 80],
    xaxisKeyList: ['date'],
    dataKey: 'value',
    isUnit: true
  },
  {
    category: 'h24_bsn',
    text: '流域24時間雨量',
    unit: 'mm',
    ticks: [150, 200, 250, 300],
    domains: [0, 300],
    xaxisKeyList: ['date'],
    dataKey: 'value',
    isUnit: true
  },
  {
    category: 'h48_bsn',
    text: '流域48時間雨量',
    unit: 'mm',
    ticks: [200, 250, 300, 400],
    domains: [0, 400],
    xaxisKeyList: ['date'],
    dataKey: 'value',
    isUnit: true
  },
  {
    category: 'of_bsn',
    text: '流域流出量',
    unit: '',
    ticks: [2, 5, 8, 15],
    domains: [0, 15],
    xaxisKeyList: ['date'],
    dataKey: 'value',
    isUnit: false
  },

]
