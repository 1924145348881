import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Contents component
import MsList from '../mslist'
import MeterSlider from '../meterslider'

export default function MsSliderView(props){
  const { bsnCode, bsnName } = props

  // スライダーでどの時間を選択しているか
  const [chooseDate, setChooseDate] = useState('')

  // スライダーを変更時, 時間を更新
  function updateChooseData(props){
    setChooseDate(props)
  }
  return(
    <React.Fragment>
      <MeterSlider
        bsnCode={bsnCode}
        updateChooseDate={updateChooseData}
      />
      <MsList
        bsnCode={bsnCode}
        bsnName={bsnName}
        chooseDate={chooseDate}
      />
    </React.Fragment>
  )
}