import React from 'react'
import styled from 'styled-components'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import EvacMap from '../../contents/evacmap'

export default function EvacDashboard(){
  return(
    <React.Fragment>
      <RiskHeader />
      <ContentsWrapper>
        <EvacMap />
      </ContentsWrapper>
    </React.Fragment>
  )
}

const ContentsWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 48px);
`
