import React, { useState } from 'react'
import styled from 'styled-components'
// contents component
import Login from '../../contents/login'

export default function NoHeader(){

  return(
    <Wrapper>
      <Login />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

