import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Atomic Component
import DateTitle from '../../atoms/datetitle'

const Wrapper = styled.div`
  width: ${(props) => props.is_forecast ? '400px' : '300px'};
  background-color: ${(props) => props.is_forecast ? '#1f6fa9c4' : '#061027c4'};
  position: absolute;
  z-index: 100000;
  border-radius: 10px;
  top: 10px;
  left: 10px;
  height: 70px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function DateCard(props){
  const {text, is_forecast} = props
  return(
    <Wrapper
      is_forecast={is_forecast}
      is_btn={false}
    >
      <DateTitle
        color={'white'}
        fontWeight={'bold'}
        fontSize={'32px'}
      >
        {text} {is_forecast ? '予報' : ''}
      </DateTitle>
    </Wrapper>
  )
}