import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroller'

import axios from 'axios'
import useSWRInfinite from 'swr/infinite'
import { config } from '../../config.jsx'
import { UseInterval } from '../../func/useinterval'
import { dateToMMDDHHmm } from '../../func/datetoview'

// Item Components
import Meter from '../../items/meter'
import PullDown from '../../items/pulldown'
import Toggles from '../../items/toggles'
import LoadingMeter from '../../items/loadingmeter'

InfiniteRank.propTypes = {
  // コードを更新する関数
  updateCode: PropTypes.func,
  // 緯度経度を更新する関数
  updateLatlng: PropTypes.func,
  // 日付を変更する関数
  updateChooseDate: PropTypes.func,
}

// ランクのラッパー
const RankWrapper = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  min-height: 80vh;
  &::-webkit-scrollbar{
    display: none;
  }
`
const TabWrapper = styled.div`
  margin: 0.25rem 0;
`

// メニューに表示する項目リスト
const menuArray = [
  {
    menu: 'h1_msh',
    text: '1時間雨量',
    unit: 'mm/h'
  },
  {
    menu: 'h24_msh',
    text: '24時間雨量',
    unit: 'mm'
  },
  {
    menu: 'h48_msh',
    text: '48時間雨量',
    unit: 'mm'
  },
  {
    menu: 'sr_msh',
    text: '土壌雨量指数',
    unit: ''
  },
  {
    menu: 'rsk_msh',
    text: '土砂災害発生リスク',
    unit: ''
  },
  // {
  //   menu: 'rfs_msh',
  //   text: '河川洪水発生リスク',
  //   unit: ''
  // },
  {
    menu: 'h1_bsn',
    text: '流域平均1時間雨量',
    unit: 'mm/h'
  },
  {
    menu: 'h24_bsn',
    text: '流域平均24時間雨量',
    unit: 'mm'
  },
  {
    menu: 'h48_bsn',
    text: '流域平均48時間雨量',
    unit: 'mm'
  },
  {
    menu: 'of_bsn',
    text: '流域平均流出量',
    unit: ''
  },
]


const fetcher = url => axios.get(url).then(res => res.data)

export default function InfiniteRank(props){
  const { updateCode, updateLatlng, updateChooseDate } = props

  // 現在選択されている種類が何かを管理する
  const [chooseMenu, setChooseMenu] = useState(menuArray[0].menu)
  // 過去か現在バージョン化を管理する
  const [isPast, setIsPast] = useState(false)

  const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite(
    (pageCount) =>
      `${config.restUrl}/api/rain/rank/${chooseMenu}/${isPast}/?page=${pageCount + 1}`,
    fetcher,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // 再試行は5回まで
        if(retryCount >= 2) return
        // 5秒後に再試行
        setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
      },
      refreshInterval: 300000,
      persistSize: true
    }
  )

  const isLoadingInitialData = !data && !error
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === "undefined")
  const isEmpty = data?.[0].results.length === 0
  const isRefreshing = isValidating && data && data.length === size

  // 子コンポーネントで更新されたとき用関数
  function updateIsPast(props){
    setIsPast(props)
  }
  function updateChooseMenu(props){
    setChooseMenu(props)
  }

  const loadMore = () => {
    if(!isLoadingMore && !isEmpty){
      setSize(size + 1)
    }
  }

  const onclickUpdate = (props) => {
    updateCode(props[0])
    updateLatlng(props[1])
    if(!props[3]){
      updateChooseDate(props[2])
    }
  }

  if(isLoadingInitialData){
    return(
      <React.Fragment>
        <TabWrapper>
          <Toggles
            is_true={isPast}
            text={'過去モード'}
            updateState={updateIsPast}
          />
          <PullDown
            menuArray={menuArray}
            menu={chooseMenu}
            updateMenu={updateChooseMenu}
          />
        </TabWrapper>
        <RankWrapper>
          {
            [...Array(6)].map((elem, i) => (
              <LoadingMeter key={`loading-mtr-${i}`} />
            ))
          }
        </RankWrapper>
      </React.Fragment>
    )
  }

  // 追加ダウンロード
  const results = []
  data.map((item) =>
    results.push(...item.results)
  )

  return(
    <React.Fragment>
      <TabWrapper>
        <Toggles
          is_true={isPast}
          text={'過去モード'}
          updateState={updateIsPast}
        />
        <PullDown
          menuArray={menuArray}
          menu={chooseMenu}
          updateMenu={updateChooseMenu}
        />
      </TabWrapper>
      <RankWrapper>
        <InfiniteScroll
          class='infinitescroll-area'
          pageStart={0}
          loadMore={loadMore}
          hasMore={!isEmpty}
          initialLoad={false}
          loader={<LoadingMeter />}
          useWindow={false}
        >
          <div>
            {results.map((elem, i) =>
              <Meter
                key={`ranks-${i}`}
                headtitle={elem.txt_address_name || elem.txt_name}
                subtitle={dateToMMDDHHmm(elem.tsm_u_date)}
                value={elem.dc_value_num}
                colorclass={elem.colorclass}
                is_btn={true}
                unit={menuArray.find(element => element.menu === chooseMenu).unit}
                updateStateFunc={onclickUpdate}
                funcProps={[elem.msh_code, elem.latlng, elem.tsm_u_date, isPast]}
              />
            )}
          </div>
        </InfiniteScroll>
      </RankWrapper>
    </React.Fragment>
  )
}
