import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLeafletContext } from '@react-leaflet/core'
import L from 'leaflet'
import 'leaflet.vectorgrid'

RiverTile.propTypes = {
  // ベクタータイルのURL
  urlHead: PropTypes.string,
  // マウスホバー時のポップアップの情報を変更する関数
  updatePopupData: PropTypes.func
}

export default function RiverTile(props){
  const { urlHead, updatePopupData } = props
  const url = urlHead + '{z}/{x}/{y}.pbf'
  const { layerContainer, map } = useLeafletContext()

  let options = {
    className: 'riverLayers',
    rendererFactory: L.canvas.tile,
    vectorTileLayerStyles:{
      river_geom: function(properties, zoom){
        return{
          fill: false,
          fillOpacity: 0,
          color: 'rgb(0, 0, 0)',
          weight: 0
        }
      }
    },
    interactive: true
  }

  const vectorGrid = L.vectorGrid.protobuf(url, options)

  // マウスホバー時の挙動
  vectorGrid.on('mouseover', function(e){
    let name = e.layer.properties.name
    updatePopupData(
      {
        'name' : `${name}`
      },
      true
    )
  })
  vectorGrid.on('mouseout', function(e){
    updatePopupData({}, false)
  })

  const container = layerContainer || map

  const onUpdate = (e) => {
    setTimeout(
      () => {
        container.removeLayer(vectorGrid)
      },
      250
    )
  }

  useEffect(() => {
    container.addLayer(vectorGrid)
    return () => {
      onUpdate();
    }
  }, [])

  return null

}