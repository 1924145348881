import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Contents Component
import RiverGraph from '../rivergraph'
import TelList from '../tellist'
import CrsList from '../crslist'

// 水位系一覧のラッパー
const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50vw;
  padding-left: 5vw;
  padding-right: 1rem;
  margin: 0 0.5rem 0.5rem 0.5rem;
  overflow-y: scroll;
  height: 100%
`

// グラフラッパー
const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50vw;
  padding-right: 5vw;
  padding-left: 1rem;
  margin: 0.5rem 0;
  height: auto;
  overflow-y: scroll;
`

export default function RiverBoard(props){
  const { bsnCode, bsnName } = props

  // 選択中の水位計のID
  const [mtrCode, setMtrCode] = useState(null)
  // 選択中の水位計の種類
  const [mtrCategory, setMtrCategory] = useState('')

  function updateChooseMtr(code, category){
    setMtrCode(code)
    setMtrCategory(category)
  }

  return(
    <React.Fragment>
      <ListWrapper>
        <TelList
          bsnCode={bsnCode}
          mtrCode={mtrCode}
          bsnName={bsnName}
          updateChooseMtr={updateChooseMtr}
        />
        <CrsList
          bsnCode={bsnCode}
          mtrCode={mtrCode}
          bsnName={bsnName}
          updateChooseMtr={updateChooseMtr}
        />
      </ListWrapper>
      <GraphWrapper>
        <RiverGraph
          mtrCode={mtrCode}
          mtrCategory={mtrCategory}
        />
      </GraphWrapper>
    </React.Fragment>
  )

}