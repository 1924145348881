const getRainStyle = (value) => {
  if(value === 0){
    return 'rgb(242, 242, 255)'
  }
  else if(value === 1){
    return 'rgb(160, 210, 255)'
  }
  else if(value === 2){
    return 'rgb(33, 140, 255)'
  }
  else if(value === 3){
    return 'rgb(0, 65, 255)'
  }
  else if(value === 4){
    return 'rgb(250, 245, 0)'
  }
  else if(value === 5){
    return 'rgb(255, 153, 0)'
  }
  else if(value === 6){
    return 'rgb(255, 40, 0)'
  }
  else if(value === 7){
    return 'rgb(180, 0, 104)'
  }
  return ''
}
const getSrStyle = (value) => {
  if( value === 0){
    return 'rgb(160, 210, 255)'
  }
  else if(value === 1){
    return 'rgb(0, 65, 255)'
  }
  else if(value === 2){
    return 'rgb(250, 245, 0)'
  }
  else if(value === 3){
    return 'rgb(255, 153, 0)'
  }
  else if(value === 4){
    return 'rgb(255, 40, 0)'
  }
  else if(value === 5){
    return 'rgb(180, 0, 104)'
  }
  return ''
}
const getRskStyle = (value) => {
  if(value === 0){
    return 'rgb(250, 245, 0)'
  }
  else if(value === 1){
    return 'rgb(255, 153, 0)'
  }
  else if(value === 2){
    return 'rgb(255, 40, 0)'
  }
  else if(value === 3){
    return 'rgb(180, 0, 104)'
  }
  return ''
}

export const getVectorTileStyles = (value, category) => {
  if(category === 'rsk_msh' || category === 'rfs_msh' || category === 'p44_chr'){
    return getRskStyle(value)
  }
  else if(category === 'sr_msh' || category === 'of_bsn'){
    return getSrStyle(value)
  }
  else{
    return getRainStyle(value)
  }
}

export const getTelVectorStyle = (value) => {
  const StyleArray = [
    '#4d4d4e',
    '#f2f2ff',
    '#40b2e8',
    '#3aab68',
    '#faf600',
    '#ff2600',
    '#b40069',
    '#0a0412'
  ]
  return StyleArray[value]
}