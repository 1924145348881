import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import MeterSlider from '../../contents/meterslider'
import TelMapView from '../../contents/telmapview'

TelMapDashboard.ropTypes = {
  // 選択しているコード(固定)
  chooseCode: PropTypes.number,
  // 選択している時間
  chooseDate: PropTypes.number,
  // 選択している時間を更新する関数
  updateChooseDate: PropTypes.func,
}

const AppWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: calc(100vh - 48px);
  flex-direction: column;
`

const ContentsWrapper = styled.div`
  display: flex;
  padding: 0 0 1rem 0;
  overflow-y: hidden;
  height: 100%;
`

export default function TelMapDashboard(props){
  const {chooseCode, chooseDate, updateChooseDate} = props
  return(
    <React.Fragment>
      <RiskHeader />
      <AppWrapper>
        <MeterSlider
          bsnCode={props.chooseCode}
          updateChooseDate={props.updateChooseDate}
        />
        <ContentsWrapper>
          <TelMapView
            chooseDate={Number(chooseDate)}
          />
        </ContentsWrapper>
      </AppWrapper>
    </React.Fragment>
  )
}