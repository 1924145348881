import React from 'react'
import styled from 'styled-components'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import Top from '../../contents/top'

export default function TopPage(props){
  return(
    <React.Fragment>
      <RiskHeader />
      <ContentsWrapper>
        <LeftContents>
          <Top />
        </LeftContents>
        <RightContents>
        </RightContents>
      </ContentsWrapper>
    </React.Fragment>
  )
}
const ContentsWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const LeftContents = styled.div`
  width: 100vw;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const RightContents = styled.div`
  width: 0vw;
`