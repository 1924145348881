import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Item Component
import Meter from '../../items/meter'
import LoadingMeter from '../../items/loadingmeter'

// Atom Component
import ErrorMsg from '../../atoms/errormsg'

// user defined function
import { get_colorclass_from_class } from '../../func/getcolor'

const fetcher = url => axios.get(url).then(res => res.data)
export default function MsList(props){
  const { bsnCode, bsnName, chooseDate } = props

  const { data, error } = useSWR(
    () => bsnCode &&
      `${config.restUrl}/api/mtr/slider/mtr/${bsnCode}/`,
    fetcher,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // 再試行は5回まで
        if(retryCount >= 2) return
        // 5秒後に再試行
        setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
      },
      refreshInterval: 300000
    }
  )

  // データのローディングチェック
  const isLoading = !data && !error
  // データが取得できたかを確認
  const isData = !isLoading && !error
  // データの中から同じ時間のみをフィルター
  const viewData = isData && data.results.filter(elem => elem.bints === chooseDate)

  if(isLoading){
    return(
      <ListWrapper>
        <TitleWrapper>
          {
            bsnName === '九州その他' ? '大分二級河川水位(パーセント)'
            : bsnName + '水系水位(パーセント)'
          }
        </TitleWrapper>
        {
          [...Array(15)].map((elem, i) => (
            <LoadingMeter key={`loading-mtr-${i}`} />
          ))
        }
      </ListWrapper>
    )
  }

  if(error || viewData.length === 0){
    return(
      <ErrorListWrapper>
        <TitleWrapper>
          {
            bsnName === '九州その他' ? '大分二級河川水位(パーセント)'
            : bsnName + '水系水位(パーセント)'
          }
        </TitleWrapper>
        <ErrorMsg msg={'データが見つかりませんでした'} />
      </ErrorListWrapper>
    )
  }

  return(
    <ListWrapper>
      <TitleWrapper>
        {
          bsnName === '九州その他' ? '大分二級河川水位(パーセント)'
          : bsnName + '水系水位(パーセント)'
        }
      </TitleWrapper>
      {
        viewData.map((elem, i) => (
          <Meter
            key={elem.code}
            headtitle={`${elem.name} - ${elem.river_name}`}
            subtitle={elem.dates}
            value={elem.value !== null ? elem.value : '欠測'}
            colorclass={get_colorclass_from_class(elem.colorclass)}
            is_btn={true}
            isChoosed={false}
            unit={elem.colorclass === 1 ? 'm' : elem.colorclass === 0 ? '' : '%'}
            changes={elem.diff_flag}
          />
        ))
      }
    </ListWrapper>
  )
}

const TitleWrapper = styled.div`
  margin: 1rem 0;
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  width: 100%;
`

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 5vw;
  overflow-y: scroll;
  &&& .meter{
    margin: 0.3rem;
    padding: 0.5rem;
    min-width: 180px;
    max-width: 240px;
    min-height: 82px;
  }
`

const ErrorListWrapper = styled(ListWrapper)`
  height: 100%;
`