import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// 引数の型を指定する
HeadTitle.propTypes = {
  children: PropTypes.string
}

// style定義
const StyleHeadTitle = styled.div`
  text-align: left;
  font-weight: bolder;
  font-size: 16px;
  letter-spacing: 0.05em;
`

export default function HeadTitle(props){
  // 引数を取得
  const {children} = props

  return(
    <StyleHeadTitle>{children}</StyleHeadTitle>
  )
}