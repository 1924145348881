import React, { useState } from 'react'
import styled from 'styled-components'

// Template components
import MsDashboard from '../../templates/msdashboard'

export default function MeterSlider(){
  // 水系番号
  const [bsnCode, setBsnCode] = useState(890918)
  // 水系名
  const [bsnName, setBsnName] = useState('大野川')

  // 選択している水系の情報を更新する
  function updateBsnStatus(code, name){
    setBsnCode(code)
    setBsnName(name)
  }
  
  return(
    <MsDashboard
      bsnCode={bsnCode}
      bsnName={bsnName}
      updateBsnStatus={updateBsnStatus}
    />
  )
}