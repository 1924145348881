import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

StatusBadge.propTypes = {
  // バッジに表示する文字
  text: PropTypes.string,
  // 文字の色
  color: PropTypes.string,
  // 文字の大きさ
  size: PropTypes.string
}

const StyleStatusBadge = styled.div`
  text-align: center;
  margin: 0.25rem;
  padding: 0.25rem;
  border-radius: 5px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backColor};
  font-size: ${(props) => props.size};
  font-weight: bold;
`

export default function StatusBadge(props){
  const { text, backColor, color, size } = props
  return(
    <StyleStatusBadge
      color={color}
      backColor={backColor}
      size={size}
    >
      {text}
    </StyleStatusBadge>
  )
}