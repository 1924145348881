import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// 引数の型を指定する
Value.propTypes = {
  // 雨量といった値とインフラ情報が入るため
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  unit: PropTypes.string
}

// styled-componentでstyleを定義
const StyleValue = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  justify-contents: center;
  width: 100%;
  letter-spacing: 0.05em;
`

export default function Value(props) {
  // 引数を取得
  const { value, unit } = props

  return(
    <StyleValue>
      {value} {unit}
    </StyleValue>
  )
}