import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'

import { Slider, Handles, Tracks, Ticks } from 'react-compound-slider'
import {interval} from 'rxjs';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'

// Atom component
import Handle from '../../atoms/handle'
import Track from '../../atoms/track'
import Tick from '../../atoms/tick'
import BtnIcon from '../../atoms/btnicon'


Sliders.propTypes = {
  // 現在選択されている時刻のインデックス位置
  chooseNum: PropTypes.number,
  // 再生のインターバル
  intervals: PropTypes.number,
  // 日付リスト axiosで取得したものが入る
  dataArray: PropTypes.array,
  // 選択時刻インデックス変更時に更新する関数
  updateChooseNum: PropTypes.func,
  // 日付をアップデートする関数，スライダーが変化したときに更新すること
  updateChooseDate: PropTypes.func,
}

// スライダーのデザイン設定
const StyleSliders = styled(Slider)`
  position: relative;
  width: calc(100% - 2rem);
  z-index: 10000;
  margin-right: 1rem;
  margin-left: 1rem;
  top: 20px;
  height: 55px
`
// スライダーのレールスタイル設定
const StyleRail = styled.div`
  position: absolute;
  width: 100%;
  height: 10px;
  margin-top: 0.85rem;
  border-radius: 5px;
  background-color: #2D4573;
`
// スライダーラッパー設定
const SliderWrapper = styled.div`
  position: absolute;
  display: flex;
  height: 75px;
  max-height: 100px;
  width: 27.5vw;
  min-width: 350px;
  left: 0.5rem;
  bottom: 0.5rem;
  background-color: #061027c4;
  z-index: 100000;
  border-radius: 10px;
  padding: 0 0.5rem;
`
// ボタンアイコン設定
const shareBtnStyle = css`
  color: white;
  width: 2.5rem;
  height: 2.5rem;
`
const LeftBtn = styled(ChevronLeftIcon)`
  ${shareBtnStyle}
`
const RightBtn = styled(ChevronRightIcon)`
  ${shareBtnStyle}
`
const PlayBtn = styled(PlayArrowIcon)`
  ${shareBtnStyle}
`
const StopBtn = styled(StopIcon)`
  ${shareBtnStyle}
`

export default function Sliders(props){
  const {chooseNum, intervals, dateArray, updateChooseNum, updateChooseDate} = props
  const length = dateArray.length - 1
  const domains = [0, length]

  // 端に来た時ボタンを消す
  const [isDisabledLeftBtn, setIsDisabledLeftBtn] = useState(
    false
  );
  const [isDisabledRightBtn, setIsDisabledRightBtn] = useState(
    false
  );
  // 現在再生しているか
  const [isPlay, setIsPlay] = useState(false)

  // 再生中の挙動
  useEffect(() => {
		if(isPlay){
	    const timeoutId = interval(intervals).subscribe(() => {
	      if(chooseNum === length) updateChooseNum(0)
				else updateChooseNum(chooseNum + 1)
	    })
	    return () => {
	      timeoutId.unsubscribe()
	    }
		}
  }, [isPlay, chooseNum])

  // スライダーの位置が動いたときの挙動
  useEffect(() => {
    // 左右ボタンが端に行っていた場合、誤操作を防ぐために
    if(isDisabledLeftBtn && (chooseNum !== 0)) setIsDisabledLeftBtn(false)
    else if(!isDisabledLeftBtn && (chooseNum === 0)) setIsDisabledLeftBtn(true)
    if(isDisabledRightBtn && (chooseNum !== length)) setIsDisabledRightBtn(false)
    else if(!isDisabledRightBtn && (chooseNum === length)) setIsDisabledRightBtn(true)
  }, [chooseNum])

  // ボタンクリック時の挙動
  const handleChange = e => {
		if(e[0] !== chooseNum && chooseNum !== -1) updateChooseNum(e[0])
	}

	const rightClick = e => {
		if(chooseNum !== (length - 1)) updateChooseNum(chooseNum + 1)
    else{
      updateChooseNum(length)
      setIsDisabledRightBtn(true)
    }
	}

	const leftClick = e => {
		if((chooseNum - 1) !== 0) updateChooseNum(chooseNum - 1)
    else{
      updateChooseNum(0)
      setIsDisabledLeftBtn(true)
    }
	}

	const playButton = e => {
		setIsPlay(!isPlay)
	}

  return(
    <SliderWrapper>
      <BtnIcon
        is_btn={ !isDisabledLeftBtn && !isPlay }
        lable_text={'before step'}
        IconComponent={<LeftBtn />}
        onClickFunc={leftClick}
      />
      <BtnIcon
        is_btn={ true }
        label_text={isPlay ? 'Stop' : 'Play'}
        onClickFunc={playButton}
        IconComponent={ isPlay ? <StopBtn /> : <PlayBtn /> }
      />
      <StyleSliders
        domain={domains}
        step={1}
        mode={2}
        values={[chooseNum]}
        onChange={handleChange}
      >
        <StyleRail />
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className='slider-handler'>
              { handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domains}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className='slider-tracker'>
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks count={length}>
          {({ ticks }) => (
            <div className='slider-ticks'>
              {ticks.map(tick => (
                <Tick
                  key={tick.id}
                  tick={tick}
                  count={ticks.length}
                />
              ))}
            </div>
          )}
        </Ticks>
      </StyleSliders>
      <BtnIcon
        is_btn={ !isDisabledRightBtn && !isPlay }
        label_text={'next step'}
        onClickFunc={rightClick}
        IconComponent={<RightBtn />}
      />
    </SliderWrapper>
  )
}