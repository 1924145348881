import React from 'react'
import styled from 'styled-components'

import TopPage from '../../templates/toppage'


export default function Top(){
  return (
    <TopPage />
  )
}