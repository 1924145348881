import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Label
} from 'recharts'

// Atomic Component
import GraphToolTip from '../../atoms/graphtooltip'

AreaGraph.propTypes = {
  // グラフで表示するデータ，REST APIのresultsの中身を持ってくれば良い
  data: PropTypes.array,
  // グラフの上限と下限
  domains: PropTypes.array,
  // グラフ上の単位 空の場合あり
  unit: PropTypes.string,
  // グラフのx軸のキーとなるリスト
  xaxisKeyList: PropTypes.array,
  // データのキー，ここではvalue
  dataKey: PropTypes.string,
  // グラフに引く線の指定，値と線の色が入っている
  lines: PropTypes.array,
  // グラフに引く線の太さ，デフォルトは4
  lineWidth: PropTypes.number,
  // データの種類
  category: PropTypes.string,
  // データのボーダー, デフォルトは空
  borderArray: PropTypes.array,
  // 間隔をデータ通りにするkey名
  mejer: PropTypes.string,

}

const GraphWrapper = styled.div`
  display: flex;
  height: calc(100% - 1rem);
  color: white;
  background-color: #17295a;
  padding: 0.5rem 2rem 0.5rem 1rem;
  border-radius: 0 0 5px 5px;
`
// 目盛りにテキストをつける
const axisStyles = {
  fontSize: '16px',
  fill: 'white',
}

export default function AreaGraph(props){
  const {
      data,
      domains,
      unit,
      xaxisKeyList,
      dataKey,
      lines,
      lineWidth,
      category,
      borderArray,
      mejer
    } = props

  const dataLength = data.length - 1
  // 予測がある場合，予測の割合を入れる
  // 予測のみのデータ
  const predDataObj = data.filter(e => e.is_predict && e.value !== null)
  // このデータが空の場合は，予測がないと判断
  const isPredData = predDataObj.length !== 0
  // 最も現時刻に近い予測
  const firstPredPoint = isPredData && predDataObj[0][mejer]
  // 最も現時刻から遠い予測
  const lastPoint = isPredData && predDataObj[predDataObj.length - 1][mejer]
  // 実測と予測の境界点
  const boundaryPercent = predDataObj.length !== 0 && (1 - (lastPoint - firstPredPoint) / (lastPoint - data[0][mejer])) * 100

  return(
    <GraphWrapper>
      <ResponsiveContainer>
        <AreaChart
          data={data}
          margin={{
            top: 10, bottom: 10
          }}
          baseValue={domains[0]}
        >
          <CartesianGrid strokeDasharray="4 4"/>
          {
            mejer && <XAxis
              key={0}
              xAxisId={0}
              dataKey={mejer}
              tick={axisStyles}
              type='number'
              scale='time'
              tickFormatter={(elem) => moment(elem, 'X').subtract(9, 'hours').format('HH:mm')}
              domain={[data[0][mejer], data[data.length - 1][mejer]]}
            />
          }
          {
            xaxisKeyList.map((elem, i) =>
              <XAxis
                key={i + 1}
                xAxisId={i + 1}
                dataKey={xaxisKeyList[i]}
                allowDuplicatedCategory={false}
                tick={axisStyles}
              />
          )}
          <YAxis
            dataKey={dataKey}
            unit={unit}
            domain={domains}
            interval={0}
            axisLine={true}
            tickLine={true}
            tick={axisStyles}
          />
          <Tooltip
            content={
              <GraphToolTip
                unit={unit}
                category={category}
                brdr={borderArray}
              />
            }
          />
          <defs>
            <linearGradient id="xGradientColor" x1='0' y1='0' x2='100%' y2='0'>
            {
              boundaryPercent ?
                <React.Fragment>
                  <stop offset='0%' stopColor='rgba(49, 138, 183, 1)' />
                  <stop offset={`${boundaryPercent}%`} stopColor='rgba(49, 138, 183, 1)' />
                  <stop offset={`${boundaryPercent}%`} stopColor='rgba(49, 138, 183, 0.5)' />
                  <stop offset='100%' stopColor='rgba(49, 138, 183, 0.5)' />
                </React.Fragment>
              :
                <React.Fragment>
                  <stop offset='0%' stopColor='#318ab7' />
                  <stop offset='100%' stopColor='#318ab7' />
                </React.Fragment>

            }
            </linearGradient>
          </defs>
          <Area
            dataKey={dataKey}
            stroke='url(#xGradientColor)'
            fill='url(#xGradientColor)'
            strokeWidth={3}
            unit={unit}
            dot={false}
          />
          {
            lines.map((elem, i) =>
              <ReferenceLine
                key={elem.color}
                y={lines[i].target}
                stroke={lines[i].color}
                strokeWidth={lineWidth ? lineWidth : 3}
              />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </GraphWrapper>
  )

}