import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { useLocation } from 'react-router-dom'
import { config } from '../../config.jsx'

// Atomic Components
import DateClock from '../../atoms/dateclock'
import LinkBtn from '../../atoms/linkbtn'
import StatusBadge from '../../atoms/statusbadge'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: 90vw;
  padding: 0 5vw;
  border-bottom: thin ridge #15335f;
  justify-content: space-between;
  background-color: ${(props) => props.backColor};
`
const BtnWrapper = styled.div`
  display: flex;
`

// メニューボタンリスト
const btnArray = [
  {
    category: 'top',
    text: 'トップ',
    url: '/',
    isOpen: true,
  },
  {
    category: 'risk',
    text: 'リスク分析',
    url: '/risk',
    isOpen: true,
  },
  {
    category: 'river',
    text: '水位分析',
    url: '/river',
    isOpen: true,
  },
  {
    category: 'telmap',
    text: '水位地図',
    url: '/telmap',
    isOpen: true,
  },
  {
    category: 'msriv',
    text: '水位変化',
    url: '/msriv',
    isOpen: true,
  },
  {
    category: 'shelter',
    text: '避難所',
    url: '/shelter',
    isOpen: false,
  },
  {
    category: 'shelmap',
    text: '避難所地図',
    url: '/shelmap',
    isOpen: false,
  },
  {
    category: 'evac',
    text: '避難情報',
    url: '/evac',
    isOpen: false,
  },
  {
    category: 'login',
    text: 'ログアウト',
    url: '/login',
    isOpen: true,
  }
]

const fetcher = url => axios.get(url).then(res => res.data)

// 大分県領域 funcType : prefにアクセスできるIDリスト
const ACCESSIBLE_ID = config['accessibleId']

export default function RiskHeader(){
  const location = useLocation()

  // ユーザー情報取得
  const userName = JSON.parse(localStorage.getItem('user'))
  const isPrefUser = ACCESSIBLE_ID.includes(userName['id'])

  const { data, error } = useSWR(
    () => isPrefUser &&
      `${config.restUrl}/api/pref/is/disaster/respound/`,
    fetcher,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCound }) => {
        // 再試行は5回まで
        if(retryCound >= 2) return
        // 5秒後に再試行
        setTimeout(() => revalidate({ retryCount: retryCound + 1}), 50000)
      },
      refreshInterval: 300000
    }
  )

  const isLoading = !data && !error

  if(isLoading || error){
    return(
      <Wrapper backColor={'#061027'}>
        <BtnWrapper>
          {
            btnArray.map((elem, i) => (
              (isPrefUser || elem.isOpen) &&
                <LinkBtn
                  key={i.toString()}
                  text={elem.text}
                  url={elem.url}
                  isChoosed={elem.url === location.pathname}
                  choosedBackColod={'#223C6D'}
                />
            ))
          }
        </BtnWrapper>
        <DateClock />
      </Wrapper>
    )
  }

  const isDisasterRespound = data.results

  return(
    <Wrapper
      backColor={isDisasterRespound ? '#c00000cc' : '#061027'}
    >
      <BtnWrapper>
        {
          btnArray.map((elem, i) => (
            (isPrefUser || elem.isOpen) &&
              <LinkBtn
                key={i.toString()}
                text={elem.text}
                url={elem.url}
                isChoosed={elem.url === location.pathname}
                choosedBackColod={isDisasterRespound ? '#f56161cc' : '#223C6D'}
              />
          ))
        }
      </BtnWrapper>
      {
        !isLoading && <StatusBadge
          text={isDisasterRespound ? '災害対応中' : '平常運用中'}
          color={'white'}
          size={'20px'}
        />
      }
      <DateClock />
    </Wrapper>
  )
}