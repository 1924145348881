import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const DefaultTick = styled.div`
  position: absolute;
  margin-top: 2.15rem;
  margin-left: -0.5;
  width: 2px;
  height: 1rem;
  background-color: white;
  left: ${(props) => props.tick.percent}%;
`

export default function Tick(props){
  const {tick, count, time, date} = props

  return(
    <div>
      <DefaultTick
        tick={tick}
      />
    </div>
  )

}