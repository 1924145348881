export const getGraphLine = (lists, label) => {
  if(!lists) return []
  const linelists = []

  const telColor = ['#2d8457', '#b7b122', '#c02518', '#880e58', '#0a0412']
  const rainColor = ['#b7b122', '#b5711b', '#c02518', '#880e58']
  const JMAColor = ['#b7b122', '#c02518', '#0c000c']

  if(lists.length === 4){
    for(let i=0; i<lists.length; i++){
      linelists.push(
        {
          target: lists[i],
          color: rainColor[i],
          label: label ? label[i] : lists[i]
        }
      )
    }
    return linelists
  }
  else if(lists.length === 3){
    for(let i=0; i<lists.length; i++){
      linelists.push(
        {
          target: lists[i],
          color: JMAColor[i],
          label: label ? label[i] : lists[i]
        }
      )
    }
    return linelists
  }
  else if(lists.length === 5){
    for(let i=0; i<lists.length; i++){
      if(lists[i]){
        linelists.push(
          {
            target: lists[i],
            color: telColor[i],
            label: label ? label[i] : lists[i]
          }
        )
      }
    }
    return linelists
  }
}