import React, { useState } from 'react'

// Template components
import TelMapDashboard from '../../templates/telmapdashboard'

export default function TelMap(){
  // 選択している時間
  const [chooseDate, setChooseDate] = useState(null)
  // 選択しているテレメータの水系
  const [chooseBsnCode, setChooseBsnCode] = useState(890918)

  // スライダー変更時，時間を更新
  function updateChooseDate(props){
    setChooseDate(props)
  }

  return(
    <TelMapDashboard
      chooseCode={chooseBsnCode}
      chooseDate={chooseDate}
      updateChooseDate={updateChooseDate}
    />
  )
}

const lat_center = 33.2305526
const lng_center = 131.6068285
