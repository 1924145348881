import React from 'react'
import styled from 'styled-components'
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom'

// コンポーネント
import { PrivateRoute } from '../_basicauth/_component/Privateroute'
import Login from '../pages/Login'
import Top from '../pages/Top'
import Meter from '../pages/Meter'
import River from '../pages/River'
import MeterSlider from '../pages/MeterSlider'
import NotFound from '../pages/NotFound'
import TelMap from '../pages/TelMap'

// 大分県データページコンポーネント
import Shelter from '../pages/Shelter'
import ShelterMap from '../pages/ShelterMap'
import Evac from '../pages/Evac'

const Wrapper = styled.div`
  text-align: center;
  background-color: #061027;
  width: 100vw;
  height: 100vh;
  position: fixed;
`

function Apps(){
  return(
    <Wrapper>
      <div className="col-sm-8 col-sm-offset-2">
        <Router>
          <div>
            <Routes>
              <Route exact path='/' element={ < PrivateRoute funcType={'open'} /> }>
                <Route exact path='/' element={ <Top /> } />
              </Route>
              <Route exact path='/risk/:lat/:lng' element={ < PrivateRoute funcType={'open'} /> }>
                <Route exact path='/risk/:lat/:lng' element={ <Meter /> } />
              </Route>
              <Route exact path='/risk' element={ < PrivateRoute funcType={'open'} /> }>
                <Route exact path='/risk' element={ <Meter /> } />
              </Route>
              <Route exact path='/river' element={ < PrivateRoute funcType={'open'} /> }>
                <Route exact path='/river' element={ <River /> } />
              </Route>
              <Route exact path='/telmap' element={ < PrivateRoute funcType={'open'} /> }>
                <Route exact path='/telmap' element={ <TelMap /> } />
              </Route>
              <Route exact path='/msriv' element={ < PrivateRoute funcType={'open'} /> }>
                <Route exact path='/msriv' element={ <MeterSlider /> } />
              </Route>
              <Route exact path='/shelter' element={ < PrivateRoute funcType={'pref'} /> }>
                <Route exact path='/shelter' element={ <Shelter /> } />
              </Route>
              <Route exact path='/shelmap' element={ < PrivateRoute funcType={'pref'} /> }>
                <Route exact path='/shelmap' element={ <ShelterMap /> } />
              </Route>
              <Route exact path='/evac' element={ <PrivateRoute funcType={'pref'} />}>
                <Route exact path='/evac' element={ <Evac />} />
              </Route>
              <Route path='/login' element={ <Login /> } />
              <Route path='*' element={ <NotFound /> } />
            </Routes>
          </div>
        </Router>
      </div>
    </Wrapper>
  )
}

export default Apps;