import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import BsnBtn from '../../contents/bsnbtn'
import MsSliderView from '../../contents/mssliderview'

MsDashboard.propTypes = {
  // 水系コード
  bsnCode: PropTypes.number,
  // 水系名
  bsnName: PropTypes.string,
  // 水系情報を更新する
  updateBsnStatus: PropTypes.func,
}

const AppWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: calc(100vh - 48px);
  flex-direction: column;
`

export default function MsDashboard(props){
  const { bsnCode, bsnName, updateBsnStatus } = props
  return(
    <React.Fragment>
      <RiskHeader />
      <AppWrapper>
        <BsnBtn
          bsnCode={bsnCode}
          updateBsnStatus={updateBsnStatus}
        />
        <MsSliderView
          bsnCode={bsnCode}
          bsnName={bsnName}
        />
      </AppWrapper>
    </React.Fragment>
  )
}