import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: white;
  font-size: 20px;
  font-weight: bold;
`

export default function ErrorMsg(props){
  const { msg } = props
  return(
    <Wrapper>
      {msg}
    </Wrapper>
  )
}