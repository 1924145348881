import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Item Component
import AreaGraph from '../../items/areagraph'
import MtrInfo from '../../items/mtrinfo'

// Atom Component
import ErrorMsg from '../../atoms/errormsg'

// user defined function
import { getTickList } from '../../func/getticklist'
import { getGraphLine } from '../../func/getgraphline'

RiverGraph.propTypes = {
  // 選択中の水位計コード
  mtrCode: PropTypes.number,
  // 選択中の水位計の種類識別子
  mtrCategory: PropTypes.string,
}

const fetcher = url => axios.get(url).then(res => res.data)
export default function RiverGraph(props){
  const { mtrCode, mtrCategory } = props

  const { data, error } = useSWR(
    () =>　mtrCategory && mtrCode &&
      `${config.restUrl}/api/mtr/${mtrCategory}/graph/${mtrCode}/`,
    fetcher,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // 再試行は2回まで
        if(retryCount >= 2) return
        // 5秒後に再試行
        setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
      },
      refreshInterval: 300000
    }
  )

  // ロード，エラー時の設定
  const isLoading = !data && !error
  const isData = !isLoading && !error

  if(isLoading){
    return(<React.Fragment />)
  }
  if(error){
    return(<ErrorMsg text={'データが見つかりませんでした'} />)
  }

  // グラフの設定
  const mtrBorder = [
    data.green_border,
    data.yellow_border,
    data.red_border,
    data.purple_border,
    data.max_border
  ]
  const mtrLabel = [
    '水防団待機',
    '氾濫注意',
    '避難判断',
    '氾濫危険',
    '計画高'
  ]

  const results = data.results
  // ドメインとグラフのライン設定
  const domains = getTickList(results, mtrBorder, true)
  const graphLines = getGraphLine(mtrBorder)

  return(
    <React.Fragment>
      <MtrInfo
        mtrName={data.name}
        basName={`${data.river_system_name}水系`}
        riverName={data.river_name}
        mtrDetail={data.place_info}
        mtrLat={data.latitude}
        mtrLng={data.longitude}
        mtrAddress={data.address}
        mtrDiffFlag={data.diff_flag}
        mtrValue={data.value}
        colorclass={data.colorclass}
        mtrBorder={mtrBorder}
        mtrUnit={'m'}
        mtrLabel={mtrLabel}
        isEmptyMsg={'欠測'}
      />
      <AreaGraph
        data={results}
        domains={domains}
        unit={'m'}
        mejer={'mejer'}
        xaxisKeyList={['date']}
        dataKey={'value'}
        lines={graphLines}
        lineWidth={4}
        category={mtrCategory}
        borderArray={mtrBorder}
      />
    </React.Fragment>
  )
}
