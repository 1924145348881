export const get4thMeshCode = (lat, lng) => {
  // EPSG:4326 to EPSG:3857
  // let lng = lng_4326 * 180 / 20037508.34;
  // let lat = Math.atan(Math.exp(lat_4326 / 111319.490778 * Math.PI / 180)) * 360 / Math.PI - 90;
  let code_12 = String(Math.floor(lat * 60 / 40));
  let code_34 = String(Math.floor(lng - 100));
  let code_5 = String(Math.floor((lat * 60) % 40 / 5));
  let code_6 = String(Math.floor((lng - Math.floor(lng)) * 60 / 7.5));
  let code_7 = String(Math.floor(((lat * 60) % 40) % 5 * 60 / 30));
  let code_8 = String(Math.floor(((lng - Math.floor(lng)) * 60) % 7.5 * 60 / 45));
  let code_9 = String(Math.floor(((lat * 60) % 40) % 5 * 60 % 30 / 15) * 2 + Math.floor(((lng - Math.floor(lng)) * 60) % 7.5 * 60 % 45 / 22.5) + 1);
  return Number(code_12 + code_34 + code_5 + code_6 + code_7 + code_8 + code_9);
}
