import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

LinkBtn.propTypes = {
  // ボタンに表示する文字
  text: PropTypes.string,
  // リンク先のURL
  url: PropTypes.string,
  // 選択中の場合は選択済みにする
  isChoosed: PropTypes.bool,
  // 選択中の背景色
  choosedBackColod: PropTypes.string,
}

const Wrapper = styled.div`
  display: inline-block;
  height: 48px;
  &&& a{
    color: white;
    text-decoration: none;
    font-size: 18px;
    letter-spacing: initial;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 0.75rem;
  }
  ${(props) => props.isChoosed ? css`
    font-weight: bold;
    &&& a{
      font-weight: bold;
      pointer-events: none;
    }
  ` : css`
    &:hover{
      font-weight: bold;
    }
  `}

`

export default function LinkBtn(props){
  const { text, url, isChoosed, choosedBackColod } = props

  return(
    <Wrapper isChoosed={isChoosed} color={choosedBackColod}>
      <Link to={url}>{text}</Link>
    </Wrapper>
  )
}