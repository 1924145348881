import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLeafletContext } from '@react-leaflet/core'
import L from 'leaflet'
import 'leaflet.vectorgrid'

// original function
// 各ベクタータイルの種類にあったスタイルを取得
import { getVectorTileStyles } from '../../func/getvectortilestyles'

VectorTile.propTypes = {
  // タイムスライダーで選択した時間インデックス
  chooseNum: PropTypes.number,
  // 日付を格納するリスト
  dateArray: PropTypes.array,
  // 表示するベクタータイルの種類
  category: PropTypes.string,
  // 河川のタイルか？ デザインが違うため
  is_river: PropTypes.bool,
  // ベクタータイルのURL
  urlHead: PropTypes.string,
  // ベクタータイルの最大ズーム数
  maxZoomSize: PropTypes.number,
}

export default function VectorTile(props){

  const { chooseNum, dateArray, category, urlHead, maxZoomSize, is_river } = props
  const { layerContainer, map } = useLeafletContext()

  let options = {
    className: 'rainlayers',
    rendererFactory: L.canvas.tile,
    maxNativeZoom: maxZoomSize,
    detectRetina: true,
    vectorTileLayerStyles: {
      [category]: function(properties, zoom){
        let value = properties.sint_value_cls_num
        let fillColor = ''
        let fillOption = true
        if(chooseNum === undefined){
          return{
            fill: false,
            fillOpacity: 0,
            weight: 0
          }
        }
        else if(chooseNum ===properties.choose_num){
          fillColor = getVectorTileStyles(value, category)
        }
        else{
          return{
            fill: false,
            fillOpacity: 0,
            weight: 0,
            opacity: 0
          }
        }
        return is_river ?
        {
          stroke: true,
          fillOpacity: 1,
          fill: true,
          color: fillColor,
          opacity: 0.8,
          weight: zoom/5 * zoom/4 * (value + 1)/6
        }
        : {
          fillColor: fillColor,
          fillOpacity: 0.65,
          fill: fillOption,
          weight: 0
        }
      },
      interactive: true
    }
  }

  const url = urlHead + '{z}/{x}/{y}.pbf'
  const vectorGrid = L.vectorGrid.protobuf(url, options)
  const container = layerContainer || map

  const onUpdate = (e) => {
    setTimeout(
      () => {
        container.removeLayer(vectorGrid)
      },
      100
    )
  }

  useEffect(() => {
    container.addLayer(vectorGrid);
    return () => {
      onUpdate()
    }
  }, [chooseNum, dateArray])

  return null

}