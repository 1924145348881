export const config = {
  // apiUrl: 'http://localhost:3000',
  // restUrl: 'https://risk-edison.com/rest',
  // tileUrl: 'https://risk-edison.com/tile',
  restUrl: 'https://r2.risk-edison.com/rest',
  tileUrl: 'https://r2.risk-edison.com/tile',
  // restUrl: 'http://localhost:8080',
  // tileUrl: 'http://localhost:8088',
  accessibleId: [1, 2, 4, 5, 6],
};
