import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import ShelterMapView from '../../contents/sheltermapview'

const AppWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: calc(100vh - 48px);
  flex-direction: column;
`

const ContentsWrapper = styled.div`
  display: flex;
  padding: 0 0 1rem 0;
  overflow-y: hidden;
  height: 100%;
`

export default function ShelMapDashboard(props){
  return(
    <React.Fragment>
      <RiskHeader />
      <AppWrapper>
        <ContentsWrapper>
          <ShelterMapView />
        </ContentsWrapper>
      </AppWrapper>
    </React.Fragment>
  )
}