import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Atomic Component
import Card from '../../atoms/card'
import HeadTitle from '../../atoms/headtitle'
import SubTitle from '../../atoms/subtitle'
import Value from '../../atoms/value'
import ChangeIcon from '../../atoms/changeicon'

Meter.propTypes = {
  // メーターのタイトルに入れる文字
  headtitle: PropTypes.string,
  // メーターの下に入れる文字，主に日付 MM/DD HH:mm
  subtitle: PropTypes.string,
  // メーターの値の部分，文字と数字
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  // メーターの色を指定するクラス名
  colorclass: PropTypes.string,
  // ボタンか
  is_btn: PropTypes.bool,
  // 選択済みか，デフォルトはfalse
  isChoosed: PropTypes.bool,
  // メーターの単位
  unit: PropTypes.string,
  // アイコンを表示するときの番号フラグ
  changes: PropTypes.number,
  // ボタンのときにクリックしたら状態を更新する関数
  updateStateFunc: PropTypes.func,
  // 更新する関数に与える引数
  funcProps: PropTypes.array,
}

const StyleValues = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
`

export default function Meter(props){
  // 引数を取得
  const {
      headtitle,
      subtitle,
      value,
      colorclass,
      is_btn,
      isChoosed,
      unit,
      changes,
      updateStateFunc,
      funcProps
    } = props

  // 各コンポーネントへの引数を定義
  const card_args = {
    colorclass: colorclass,
    is_btn: is_btn,
    isChoosed: isChoosed,
    updateStateFunc: updateStateFunc,
    funcProps: funcProps
  }

  return(
    <Card
      {...card_args}
    >
      <HeadTitle children={headtitle} />
      <StyleValues>
        <ChangeIcon flag={changes} />
        <Value
          value={value}
          unit={unit}
        />
      </StyleValues>
      <SubTitle children={subtitle} />
    </Card>
  )
}