import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

SwitchBtn.propTypes = {
  // ボタンのテキスト
  text: PropTypes.string,
  // 選択中か
  isChoosed: PropTypes.bool,
  // クリック時に状態を更新する関数
  updateChooseData: PropTypes.func,
  // ボタンクリック時に関数に渡す引数リスト
  propArray: PropTypes.array,
}

const Wrapper = styled.div`
  padding: 0.5rem;
  text-align: center;
  border-radius: 5px;
  margin: 0.25rem;
  background-color: ${(props) => props.isChoosed ? '#318ab7' : '#152451'};
  color: white;
  font-size: 20px;
  font-weight: bold;
  ${(props) => !props.isChoosed && css`
    &:hover{
      cursor: pointer;
      filter: brightness(1.25);
    }
  `}
`


export default function SwitchBtn(props){
  const { text, isChoosed, updateChooseData, propArray } = props
  return(
    <Wrapper
      isChoosed={isChoosed}
      onClick={
        () =>
        !isChoosed && updateChooseData ? updateChooseData(propArray) : null
      }
    >
      {text}
    </Wrapper>
  )
}