import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import { config } from '../../config.jsx'
import {get4thMeshCode} from '../../func/get4thmeshcode.jsx'

// Template components
import RiskDashboard from '../../templates/riskdashboard'

const fetcher = url => axios.get(url).then(res => res.data)
export default function Meter(){
  // 現在選択している時間
  const [chooseDate, setChooseDate] = useState('')
  // 選択している時間のインデックス
  const [chooseNum, setChooseNum] = useState(null)
  // 選択している時間が予報時間か
  const [isForecast, setIsForecast] = useState(false)
  // 選択している緯度経度
  const [latlng, setLatlng] = useState(null)
  // 選択されている場所のメッシュコード
  const [codes, setCodes] = useState(null)
  // 河川水位からのリンクのパラメータがある場合，そちらを初期値とする
  const { lat, lng } = useParams()

  useEffect(() => {
    if(lat && lng && latlng !== [lat, lng]){
      setCodes(get4thMeshCode(lat, lng))
      setLatlng([lat, lng])
    }
    else if(!lat && !lng){
      setCodes(get4thMeshCode(lat_center, lng_center))
      setLatlng([lat_center, lng_center])
    }
  }, [lat, lng])

  const { data, error } = useSWR(
    () =>
      `${config.restUrl}/api/rain/date/`,
    fetcher,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // 再試行は5回まで
        if(retryCount >= 2) return
        // 5秒後に再試行
        setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
      },
      refreshInterval: 300000
    }
  )
  // データが取得できたかを確認する
  const isLoadingComplete = data && !error
  const dateArray = isLoadingComplete ? data.result : []
  const newestDate = isLoadingComplete ? data.newest_date : ''
  
  // データが更新されたときに，もともと選択していた時刻で更新を行う
  useEffect(() => {
    if(dateArray.length !== 0){
      updateChooseNum(chooseNum === null || chooseNum < 0 ? dateArray.indexOf(newestDate) : chooseNum)
    }
  }, [dateArray, newestDate])

  // 選択された日付が変更されたときに呼び出す関数
  function updateChooseDate(props){
    if(dateArray.length !== 0){
      setChooseDate(props)
      setChooseNum(dateArray.indexOf(props))
      // 解析雨量で最新の時間と比較し，インデックスが大きければ予報フラグをtrueに
      if(dateArray.indexOf(props) > dateArray.indexOf(newestDate)){
        setIsForecast(true)
      }
      else {
        setIsForecast(false)
      }
    }
  }

  // 選択された緯度経度が変更されたとき
  function updateLatlng(props){
    setLatlng(props)
  }

  // 選択されたメッシュコードが変更されたとき
  function updateCodes(props){
    setCodes(props)
  }

  // 選択されたインデックスが変わったとき
  function updateChooseNum(props){
    setChooseNum(props)
    setChooseDate(dateArray[props])

    // 解析雨量で最新の時間と比較し，インデックスが大きければ予報フラグをtrueに
    if (props > dateArray.indexOf(newestDate)){
      setIsForecast(true)
    }
    else {
      setIsForecast(false)
    }
  }

  return (
    <RiskDashboard
      tileUrl={config.tileUrl}
      codes={codes}
      latlng={latlng}
      chooseDate={chooseDate}
      chooseNum={chooseNum}
      newestDate={newestDate}
      dateArray={dateArray}
      vectorlist={vectorlist}
      isForecast={isForecast}
      updateCodes={updateCodes}
      updateLatlng={updateLatlng}
      updateChooseDate={updateChooseDate}
      updateChooseNum={updateChooseNum}
    />
  )
}

// default center position
const lat_center = 33.2305526
const lng_center = 131.6068285

// 地図に表示するベクタータイルのリスト
const vectorlist = [
  {
    category: 'h1_msh',
    name: '1時間雨量',
    maxZoomSize: 12,
    is_river: false,
  },
  {
    category: 'h24_msh',
    name: '24時間雨量',
    maxZoomSize: 12,
    is_river: false,
  },
  {
    category: 'h48_msh',
    name: '48時間雨量',
    maxZoomSize: 12,
    is_river: false,
  },
  {
    category: 'sr_msh',
    name: '土壌雨量指数',
    maxZoomSize: 12,
    is_river: false,
  },
  {
    category: 'rsk_msh',
    name: '土砂災害発生リスク',
    maxZoomSize: 12,
    is_river: false,
  },
  // {
  //   category: 'rfs_msh',
  //   name: '河川洪水発生リスク',
  //   maxZoomSize: 12,
  //   is_river: false,
  // },
  {
    category: 'h1_bsn',
    name: '流域平均1時間雨量',
    maxZoomSize: 12,
    is_river: true,
  },
  {
    category: 'h24_bsn',
    name: '流域平均24時間雨量',
    maxZoomSize: 12,
    is_river: true,
  },
  {
    category: 'h48_bsn',
    name: '流域平均48時間雨量',
    maxZoomSize: 12,
    is_river: true,
  },
  {
    category: 'of_bsn',
    name: '流域平均流出量',
    maxZoomSize: 12,
    is_river: true,
  },
]
