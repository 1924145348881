import React, { useState } from 'react'
import styled from 'styled-components'

// Template components
import ShelterDashboard from '../../templates/shelterdashboard'

export default function Shelter(){

  // 市町村コード
  const [ctvCode, setCtvCode] = useState(null)
  // 市町村名
  const [ctvName, setCtvName] = useState(null)

  // 選択している市町村の避難所情報を更新する
  function updateCtvStatus(code, name){
    setCtvCode(code)
    setCtvName(name)
  }

  return(
    <ShelterDashboard
      ctvCode={ctvCode}
      ctvName={ctvName}
      updateCtvStatus={updateCtvStatus}
    />
  )

}
